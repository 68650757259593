

.section_wrapper {
    box-shadow: var(--box-shadow-card);
    margin: 20px 20px 0px 20px;
    padding: 20px;
    border-radius: 8px;
}

.section_wrapper_width {
    width: 40%;
    box-shadow: var(--box-shadow-card);
    margin: 20px 20px 0px 20px;
    padding: 20px;
   border-radius: 4px;
}

.section_wrapper_left_half {
    width: 60%;
    box-shadow: var(--box-shadow-card);
    margin: 20px 0px 0px 20px;
    padding: 20px;
    border-radius: 4px;
}

.section_titleCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section_title {
    font-weight: 700;
}
.section_icon {
    color: var(--default-label);
    margin-right: 4px;
}

.favoriteCon {
    display: flex;
    flex-wrap: wrap;
  margin-top: 6px;
}

.fav_itemCon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 20px;
    margin-right: 20px;
    text-decoration: none;
}

.icon {
    width: 16px;
    height: 16px;
}

.actionName {
    margin-left: 4px;
    text-align: center;
    font-size: var(--label-size);
}

/* quick stock  */
.qsItem {
    background-color: var(--light-background-color);
    color: var(--main-color);
}

.qsItem:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    cursor: pointer;
}

/* new purchase order  */
.poItem {
    background-color: var(--light-blue-color);
    color: var(--main-color);
}

.poItem:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    cursor: pointer;
}
/* new sale order  */
.soItem {
    background-color: var(--light-orange-bc);
    color: var(--orange-color);
}

.soItem:hover {
    background-color: var(--orange-color);
    color: var(--white-color);
    cursor: pointer;
}

/* new user and new role  */
.userItem {
    background-color: var(--light-brown-bc);
    color: var(--brown-color);
}

.userItem:hover {
    background-color: var(--brown-color);
    color: var(--white-color);
    cursor: pointer;
}

.addItem {
    border: 1px dashed var(--main-color);
    padding: 8px 18px;
    color: var(--main-color);
}

.addItem:hover {
    border: 1px dashed var(--main-color);
    background-color: var(--main-color);
    color: var(--white-color);
    cursor: pointer;
}

.clickHint {
    display: none;
    position: absolute;
    top: 105%;
    z-index: 1;
    color: var(--white-color);
    padding: 2px;
    font-size: var(--tag-size);
    background-color: var(--hover-title);
    border-radius: 1px;
}

.fav_itemCon:hover .clickHint {
    display: inline-block;
}

.checkMark {
    width: 18px;
    height: 18px;
    padding: 2px 2px 0 0;
    display: flex;
    align-items: right;
    justify-content: right;
   border-bottom-left-radius: 20px;
   position: absolute;
   right: 0;
   top: 0;

}
.checked {
    background-color: var(--main-color);
    color: var(--white-color);
}

.unchecked {
    background-color: var(--light-background-color);
    color: var(--gray-color);
}

/* quick stock css */
.quickStockCon {
    font-size: var(--main-size);
}
.qsSearchResultCon {
    margin-top: 10px;
    box-shadow: var(--box-shadow);
    min-height: 280px;
    padding: 20px;
}


/* sale chart */
.saleChartCon {
    margin-top: 20px;
    width: 100%;
    height: 240px;
}

.realTimeSalesCon {
    margin-left: 20px;

}

.realTimeSalesData {
    display: flex;
}

.dataCon {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 10px;
    padding: 20px 20px 30px 20px;
    border-radius: 4px;
    
}

.dataLabel {
    color: var(--input-label);
}

.dataNumber {
    margin-top: 20px;
    font-size: 1.5rem;
}

.revenueData {
    background-color: #E5E2F8;
}
.cogsData {
    background-color: #DCF9E6;
}
.profitData {
    background-color: #FFF2DE;
}

/* chart toolTip style */
.toolTipCon {
    background-color: var(--white-color);
    box-shadow: var(--box-shadow-card);
    padding: 10px;
}


.toolTipTitle {
    font-weight: 500;
}

.toolTipLabel {
    display: flex;
    margin-top: 12px;
}

/* stock board */
.LeaderBoardWrapepr {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.leadBoradTable {
    width: 100%;  
}

.leadBoradTable tr th,
.leadBoradTable tr {
    border-bottom: 1px solid var(--border-color);
}

.leadBoradTable th,
.leadBoradTable td {
    padding: 10px 0px 10px 10px;
    text-align: left;
    vertical-align: middle;
}


.leadBoradItemCon {
    display: flex;
    padding: 12px 0px;
   
}

.leadBoradItemNum1 {
    margin-right: 10px;
    font-size: 1rem;
    color: #DA1705;

}

.leadBoradItemNum2 {
    margin-right: 10px;
    font-size: 0.9rem;
    color: #F85802;
}
.leadBoradItemNum3 {
    margin-right: 10px;
    font-size: 0.85rem;
    color: #DEA30A;
}

.leadBoradItemNum {
    margin-right: 10px;
}

.productNameCon {
    width: 100%;
}


.lowStocktag {
    background-color: var(--light-brown-bc);
    color: var(--brown-color);
    padding: 2px 6px;
    border-radius: 10px;
   font-size: var(--tag-size);
}

.noStocktag {
    background-color: var(--light-red-background-color);
    color: var(--delete-btn);
    padding:2px 6px;
    border-radius: 10px;
    font-size: var(--tag-size);
}

.nodataCon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nodataIcon {
    margin-top: 20px;
    width: 42px;
    height: 42px;
    color: var(--light-gray-color);
}
.nodataText {
    margin-top: 10px;
    color: var(--gray-color);
}



.trialTag {
    background-color: var(--default-label);
    padding: 4px 10px;
    border-radius: 4px;
    font-weight: 700;
}
.daysTag {
    font-size: 1rem;
    color: var(--main-color);
    font-weight: 700;
}

.searchTypeTabsCon {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--main-color);
}
.searchTypeTab {
    margin-right: 20px;
    padding: 12px 20px;
    background-color: var(--light-background-color);
    color: var(--main-color);
    display: flex;
    align-items: center;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.searchTypeTab:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    cursor: pointer;
}

.searchTypeTab.tabSelected {
    background-color: var(--main-color);
    color: var(--white-color);
}

.taskcard {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    padding: 16px;
    border-radius: 4px;
    margin-top: 20px;
}

.taskcardTitle {
    display: flex;
    align-items: center;
    max-width: 70%;

}

.numIcon {
    width: 36px;
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content:center;
    border-radius: 18px;
    background-image: linear-gradient(45deg, #444bf7, #7073d8);
    color: var(--white-color);
}

.titleCon {
    flex: 1;
    padding-left: 20px;
    display: block;
    width: calc(100%-56px);
}

.taskBtnCon {
    min-width: 30%;
    display: flex;
    align-items: center;
}

.skipButton {
    padding: 0 20px;
    background: none;
    border: none;
    color: var(--main-color);
    text-align: center;
    text-decoration: none;
    display: block;
}
.skipButton:hover {
    text-decoration: underline;
    cursor: pointer;
}

.taskCompleteTag {
    display: flex;
    align-items: center;
    margin: 20px 0;

}

.completeIcon {
    color: var(--edit-btn);
    width: 24px;
    height: 24px;
    margin-right: 6px;
}

.taskSkipTag {
    color: var(--gray-color);
}

.explainText {
    padding-right: 16px;
    margin-top: 6px;
    color: var(--gray-color);
    margin-bottom: 8px;
}