/* supplier detai form */

.col1,
.col2 {
    flex: 1;
    min-width: 300px;
}

.col1 {
    margin-right: 20px;
}

.supplierPreviewCon {
    width: 70%;
    background-color: var(--light-background-color);
    padding: 20px;
}

/* purchase order form */
.orderNumberRow {
    background-color: var(--light-background-color);
    padding: 0 8px 12px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderNumberCon {
    display: flex;
    align-items: flex-end;

}

.emailCon {
    display: flex;
    align-items: center;
}


.orderInfoCon {
    display: flex;

}

.supplierCon {
   margin-right: 20px;
    width: 50%;
    background-color: var(--white-color);
}

.shippingCon {
    /* padding: 0px 20px; */
    margin-left: 20px;
    width: 50%;
    background-color: var(--white-color);
}


.orderItemsCon {
    padding: 10px 0px;
}


.productNameCol {
    position: relative;
    width: 30%;
}


.variantTag {
    display: inline-block;
    font-size: var(--tag-size);
    padding: 4px 6px;
    margin: 0 2px;
    text-align: center;
    border-radius: 10px;
    background-color: var(--slight-blue);
    color: var(--main-color);
}



.summaryWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.costCon {
    width: 40%;
}

.summaryCon {
    margin-bottom: 24px;
    align-self: flex-end;
    width: 50%;
}

/* purchase order list css */
.tasktagsWrapper {
    display: flex;
    flex-wrap: wrap;
}

.taskTagCon {
    display: flex;
    margin: 1px 6px;
    color: var(--main-color);
}

.tagName {
    font-size: var(--label-size);
    color: var(--font-color);
}

.orderInfoWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.basicInfoWrapper {
    width: 60%;
    display: flex;
    background-color: var(--light-background-color);
    justify-content: space-between;
    padding: 6px 8px;
}


.invoiceCon {
    width: 40%;
    background-color: var(--light-green-background-color);
    padding: 6px 8px;
}

.invoiceConPreview {
    width: 35%;
    /* background-color: var(--table-hover); */
    padding: 6px 8px;  
}

.invoiceTitle {
    color: var(--edit-btn);
    font-weight: 700;
}

/* receive stock */
.orderStockCol {
    /* background-color: var(--light-blue-color); */
    color: var(--main-color);
    width: 10%;
}

.receiveStockTitle {
    background-color: var(--edit-btn);
    color: var(--edit-btn);
}

.receiveStockCol {
    background-color: var(--light-green-background-color);
    color: var(--edit-btn);

}
/* preview purchase order */
.previewWrapper {
    margin: 10px 20px;
    display: flex;
    background-color: var(--light-background-color);
    justify-content: space-between;
    padding: 0px 8px;
}
