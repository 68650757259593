/* customer detail form */

.col1,
.col2 {
    flex: 1;
    min-width: 300px;
}
.col1 {
    margin-right: 20px;
}

.previewCon {
    width: 70%;
    background-color: var(--light-background-color);
    padding: 20px;
}




/* sale order form */

.sectionCard {
    box-shadow: var(--box-shadow-card);
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 12px;
}

.locationCon {
    padding-right: 51%;
   
}

.productNameCol {
    position: relative;
    width: 30%;
}
.productPackageNameCol {
    position: relative;
    width: 30%;
}
.productPackageName {
    background-color: var(--light-blue-color);
    color: var(--main-color);
    padding: 8px 10px;
    border-radius: 4px;
    width: fit-content;
}

.packageProductNameCol {
    position: relative;
    align-items: flex-start;
}
.packageProductTag {
    background-color: var(--light-blue-color);
    color: var(--main-color);
    padding: 4px;
    font-size: var(--tag-size);
    margin-right: 6px;
    border-radius: 4px;
}

.lowStockProductName {
    color: var(--delete-btn);
}
.stockTag {
    background-color: var( --light-green-background-color);
    color: var(--edit-btn);
    font-size: var(--tag-size);
    padding: 0px 2px;
}
.noStockTag {
    background-color: var( --light-red-background-color);
    color: var(--delete-btn);
    font-size: var(--tag-size);
    padding: 0px 2px;
}

.customerInfoCard {
    margin-top: 20px;
    background-color: var(--light-background-color);
    padding: 20px;
    box-sizing: border-box;
}


/* sale order form */
.orderActionsCon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}

.orderItemsActionCon {
    display: flex;
    align-items: center;
}

.calMarginBtn {
    margin: 0 10px -4px 0;
    padding: 8px;
    cursor: pointer;
    color: var(--orange-color);
    border-radius: 2px;
    border: 1px solid var(--orange-color);
    border-radius: 4px;
}
.calMarginBtn:hover {
    color: var(--white-color);
    background-color: var(--orange-color);
}

.updateStockBtn {
    margin: 0 10px -4px 0;
    padding: 8px;
    cursor: pointer;
    color: var(--edit-btn);
    border: 1px solid var(--edit-btn);
    border-radius: 4px;
}

.updateStockBtn:hover {
    color: var(--white-color);
    background-color: var(--edit-btn);
}

.marginbc {
    background-color: var(--light-orange-bc);
    color: var(--orange-color);

}
.costbc {
    background-color: var(--light-blue-color);

}

.searchCon {
    width: 50%;
    display: flex;
    align-items: center;
}


