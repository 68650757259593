.appCardCon {
  padding-top: 20px;
  margin: 20px 0px;
  box-shadow: var(--box-shadow-card);
  width: 200px;
  border-radius: 4px;
  box-sizing: border-box;
}
.xeroTitle {
  padding-left: 20px;
}

.xeroTag {
  color: var(--gray-color);
}
.statusCon {
  margin: 12px 0px;
}
.actionCon {
  padding: 0px 20px 1px 0px;
}
.xeroIntegrationWrapper {
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}
.integrationInfoCon {
  width: 60%;
  background-color: var(--light-background-color);
  padding: 20px;

}
.connectionCon {
  display: flex;
  flex-direction: column;
  width: 40%;
  box-sizing: border-box;
  padding: 0px 0px 0px 40px;
}
.title {
  color: var(--main-color);
  font-weight: 700;
  margin: 20px 0px;
}
.subTitle {
  color: var(--main-color);
  font-weight: 700;
  margin: 12px 0px;
}
.xeroLogoCon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.configCard {
  box-shadow: var(--box-shadow-card);
  padding: 20px 20px;
  margin: 20px;
  
}
.configSubTitle {
  font-size: 1rem;
  color: var(--main-color);
  margin-top: 20px;
}

.editBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
 
  
}
.editBtnCon {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 4px;
  width: fit-content;
  border: 1px solid var(--edit-btn);
  color: var(--font-color);
  text-decoration: none;
}
.editBtnCon:hover {
  background-color: var(--light-green-background-color);
  color: var(--edit-btn);
  cursor: pointer;
}

.statusLineItemCon {
  width: 100%;
  display: flex;
  align-items: center;
  
}

.statusLineName {
  margin-top: 14px;
  padding: 0 10px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lineItemCon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lineName {
  width: 20%;
  margin-top: 14px;
  border: 1px solid var(--border-color);
  background-color: var(--light-background-color);
  border-radius: 4px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lineValueName {
  width: 100%;
  margin-top: 14px;
  border: 1px solid var(--border-color);
  /* background-color: var(--light-background-color); */
  border-radius: 4px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineNameEmpty {
  width: 20%;
}

.divider {
  height: 30px;
  border-bottom: 1px dashed var(--main-color);
}

.arrowIcon {
  width: 15%;
  margin: 14px 0px 0px 0px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);

}
.xeroName {
  width: 30%;
}

.xeroAccountName {
  width: 20%;
}
.xeroLogo {
  width: 60px;
  height: 60px;
  margin: 0 20px 20px 20px;
  background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/xero_logo_blue.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.xeroRateName {
  width: 30%;
}
.rateTypeName {
  width: 30%;
  background-color: var(--light-background-color);
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.accountTypeName {
  width: 20%;
  background-color: var(--light-background-color);
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.companyName {
  padding: 8px 10px;
  background-color: var(--light-background-color);
  width: fit-content;
  border-radius: 4px;
  color: var(--main-color);
  margin-left: 12px;


}

.disconnectBtn {
  padding: 10px 10px;
  border: 1px solid var(--main-color);
  width: fit-content;
  border-radius: 4px;
  color: var(--main-color);
}

.disconnectBtn:hover {
  background-color: var(--main-color);
  color: var(--white-color);
  cursor: pointer;
}

.disconnectTextBtn {
  width: 100%;
  color: var(--gray-color);
  text-align: center;
  margin-bottom: 20px;
}

.disconnectTextBtn:hover {
  color: var(--main-color);
  cursor: pointer;
}

.resultWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
 
  overflow: hidden;
}
.xeroContactsCount {
  color: var(--input-label);
  margin-bottom: 12px;
}

.xeroContactsCon {
  padding: 10px 20px;
  border: 1px solid var(--main-color);
  height: calc(100% - 120px);
  overflow-y: auto;
}

.xeroProductsCon {
  padding: 10px 0;
  height: calc(100% - 140px);
  overflow-y: auto;
}

.xeroContactsCountNum {
  color: var(--main-color);
  font-weight: 700;
  margin-left: 2px;
  margin-right: 2px;
}

.xeroContactsOptionCon {
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.xeroContactsCountNumError {
  color: var(--delete-btn);
  margin-left: 2px;
  margin-right: 2px;
}

.xeroProductsEditCol {
  background-color: var(--light-green-background-color);
  color: var(--edit-btn);
}






