/* Add new product page css */

.formCon {
    margin: 20px;
    background-color: var(--light-background-color);
    border-radius: 4px;
    padding-top: 10px;
}

.basicPrice,
.attrCon,
.basicInfo {
    background-color: var(--white-color);
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
}

.basicPriceCol,
.basicInfoCol {
    /* padding: 8px; */
    display: flex;
    justify-content: space-between;
}

.attrText {
    margin: 6px 0px;
    color: var(--gray-color);
    font-size: var(--label-size);
}

.notAllowDeleteText {
    margin: 6px 0px;
    color: var(--delete-btn);
    font-size: var(--label-size); 
}

.skuCon {
    display: flex;
}

.autoGenBtn {
    align-self: flex-end;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    margin-left: 8px;
    padding: 0 8px;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    cursor: pointer;
    font-size: var(--label-size);
}

.autoGenBtn:hover {
    color: var(--white-color);
    background-color: var(--main-color);
    box-shadow: var(--box-shadow);
}


.col1,
.col2,
.col3 {
    /* width: 33.33%; */
    flex: 1;

}

.col1,
.col2 {
    margin-right: 20px;
}

.actionsGrop {
    display: flex;
    /* flex-direction: row; */
    justify-content: flex-end;
    /* margin: 8px 20px; */
    padding-left: 12px;
    /* margin-right: 20px; */
}



/* dynamic table */
.dynTable {
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--border-color);
}

.dynTable thead {
    background-color: var(--light-background-color);
}

.delLoc {
    width: 20%;
}

.dynTable th,
.dynTable td {
    font-weight: 400;
    text-align: left;
    vertical-align: middle;
    border: 1px solid var(--border-color);
}

.dynTable th,
.dynTable td {
    padding: 0 8px;
}

.dynTable tr {
    height: 32px;
}

.dynTable tr .name:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--main-color);
}

.imageWrapper {
    width: 100%;
    height: 100%;
}

.uploadBtnCon {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.uploadBtn {
    line-height: 32px;
    padding: 0px 12px;
    border: 1px solid var(--main-color);
    background-color: var(--white-color);
    color: var(--main-color);
    border-radius: 4px;
    cursor: pointer;
    border-radius: 4px;
}


.uploadBtn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    box-shadow: var(--box-shadow);
}

.uploadBtn input {
    display: none;
}

.bulkFillBtn {
    display:flex;
    align-items: center;
    justify-content: center;
    color: var(--edit-btn);
    font-size: var(--label-size);
    margin-left: 10px;
    cursor: pointer;
}



/* create product steps */
.stepsCon {
    display: flex;
    padding: 10px;
    margin: 0px 20px;
    align-items: center;
    justify-content: space-between;

}

.stepLine {
    background-color: var(--arrow-color);
    border: none;
    height: 1px;
    width: 30%;

}

.stepLineColored {
    background-color: var(--main-color);
    border: none;
    height: 1px;
    width: 30%;
}

.stepLineCompleted {
    background-color: var(--edit-btn);
    border: none;
    height: 1px;
    width: 30%;
}

.step {
    color: var(--arrow-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--label-size);
}

.stepActive {
    color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--label-size);
}

.stepCompleted {
    color: var(--edit-btn);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--label-size);
}

.stepNum {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: var(--arrow-color);
    color: var(--white-color);
    border-radius: 22px;
    margin-right: 8px;
}

.stepNumActive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: var(--main-color);
    color: var(--white-color);
    border-radius: 22px;
    margin-right: 8px;
}

.stepNumCompleted {
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: var(--edit-btn);
    color: var(--white-color);
    border-radius: 22px;
    margin-right: 8px;
}



.attrTitle {
    margin: 20px 0px 10px 0px;
}

.nextBtnCon {
    padding: 5px 25%;
}



.stockLevelCon {
    background-color: var(--white-color);
    margin: 10px;
    padding: 10px;
    border-radius: 4px;

}

.priceField {
    width: 20%;
}

.stockField {
    width: 10%;
}

/* step location tab */
.locationtabcon {
    display: flex;
    border-bottom: 1px solid var(--main-color);
    height: 38px;
    z-index: 1;
}

.locationItem {
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding: 0px 20px;
    line-height: 38px;
    background-color: var(--light-background-color);
    text-decoration: none;
    color: var(--font-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.locationItemActive {
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding: 0px 20px;
    line-height: 38px;
    background-color: var(--main-color);
    text-decoration: none;
    color: var(--white-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.locationItem:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--main-color);
}

.locationStockLevelCon {
    position: relative;
    flex: 1;
    border-top: 1px solid var(--main-color);
    box-shadow: 1px 2px 10px 0px rgba(3, 2, 2, 0.06);
}

.locationTitle {
    padding: 6px 0px;
    margin: 10px 0px 6px 0px;
    /* background-color: var(--main-color); */
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: var(--main-color);
}

/* Product List */

.attrtag {
    background-color: var(--light-blue);
    color: var(--main-color);
    margin: 0 2px;
    padding: 2px;
    font-size: var(--label-size);
}


.toggleBtn {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s;
    color: var(--gray-color);
    border-radius: 2px;

}

.toggleBtn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    cursor: pointer;
}

.productRow.open .toggleBtn {
    transform: rotate(180deg);
    background-color: var(--main-color);
    color: var(--white-color);
}

.filterSelectCon {
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.filterTypeCon {
    padding: 4px 10px;
    display: flex;
    align-items: center;
    /* border: 1px solid var(--main-color); */
    /* box-shadow: var(--box-shadow-card); */
    margin-right: 20px;
    border-radius: 20px;
}
.filterOptionCon {
    font-size: var(--label-size);
    margin-left: 10px;
    display: flex;
    padding: 4px;
    align-items: center;
    background-color: var(--slight-blue);
    color: var(--main-color);
    border-radius: 20px;
}

/* manage actions con expaned or not */

.actionsCon {
    display: none;
}

.productRow.open {
    background-color: var(--table-hover);

}

tr.actionsConOpen:hover {
    background-color: var(--white-color);

}

.actionsGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
/* 
.actionsGroup:hover {
   // background-color: var(--white-color);

} */

.actionCon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--light-green-background-color);
    width: 48px;
    height: 48px;
    margin-right: 32px;
    color: var(--edit-btn);
    border-radius: 4px;
    font-size: var(--tag-size);
    text-decoration: none;
}

.actionCon:hover {
    cursor: pointer;
}

.actionIcon {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
}

.actionCon.greenAction {
    background-color: var(--light-green-background-color);
    color: var(--edit-btn);
}

.actionCon.greenAction:hover {
    background-color: var(--edit-btn);
    ;
    color: var(--white-color);
}

.actionCon.yellowAction {
    background-color: var(--table-hover);
    color: var(--default-label);
}

.actionCon.yellowAction:hover {
    background-color: var(--default-label);
    color: var(--white-color);
}

.actionCon.blueAction {
    background-color: var(--light-blue-color);
    color: var(--main-color);
}

.actionCon.blueAction:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.actionCon.brownAction {
    background-color: var(--light-brown-bc);
    color: var(--brown-color);
}

.actionCon.brownAction:hover {
    background-color: var(--brown-color);
    color: var(--white-color)
}

.actionCon.orangeAction {
    background-color: var(--light-orange-bc);
    color: #F1510C;
}

.actionCon.orangeAction:hover {
    background-color: #F1510C;
    color: var(--white-color)
}

.actionCon.redAction {
    background-color: var(--light-orange-bc);
    color: var(--orange-color);
}

.actionCon.redAction:hover {
    background-color: var(--orange-color);
    color: var(--white-color)
}


.filterCon {
    display: flex;
justify-content: space-between;
    margin-bottom: 20px;
}

.eachFilterCol {
    width: 28%;
}

.barcodeScanCon {
    display: flex;
    width: 140px;
    margin-left: 12px;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main-color);
    padding: 2px;
    border-radius: 4px;
    line-height: 22px;
    color: var(--white-color);
    background-color: var(--main-color);
}
.barcodeScanCon:hover {
    background-color: var(--main-hover);
    cursor: pointer;
}

/* stock adjust and transfer icon css */
.stTransferIcon {

    width: 16px;
    height: 16px;
    margin-bottom: 4px;
    justify-content: center;
    align-items: center;
    background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/stock_transfer_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.stAdjustIcon {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
    justify-content: center;
    align-items: center;
    background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/stock_adjustment_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}


.proVariantCon {
    position: absolute;
    top: 58px;
    box-shadow: var(--box-shadow);
    /* height: 200px; */
    width: 100%;
    padding: 10px 20px;
    z-index: 10;
    background-color: var(--white-color);
}

.selectvariantBtn {
    color: var(--main-color);

}

.selectvariantBtn:hover {
    cursor: pointer;
    text-decoration: underline;
}

.attrOptionCon {
    margin-bottom: 20px;
}
.attrName {
    font-size: var(--label-size);
    color: var(--input-label);
    margin: 12px 0px;
}

.optionNameUnselected:hover,
.optionNameSelected {
    color: var(--white-color);
    font-size: var(--label-size);
    border: 1px dashed var(--main-color);
    background-color: var(--main-color);
    border-radius: 2px;
    margin-top: 6px;
    margin-right: 6px;
    padding: 4px 8px;
    cursor: pointer;
}

.optionNameUnselected {
    /* color: blue; */
    font-size: var(--label-size);
    border: 1px dashed var(--main-color);
    border-radius: 2px;
    margin-top: 6px;
    margin-right: 6px;
    padding: 4px 8px;
    cursor: pointer;
}

.selectOptionBtn {
    padding: 4px 8px;
    border: 1px solid var(--border-color);
    border-radius: 2px;
    color: var(--border-color);
    font-size: var(--label-size);

}

.selectOptionBtnActive:hover {
    padding: 4px 8px;
    border: 1px solid var(--main-color);
    background-color: var(--main-color) ;
    border-radius: 2px;
    color: var(--white-color);
    font-size: var(--label-size);
    cursor: pointer;
}


.selectOptionBtnActive {
    padding: 4px 8px;
    border: 1px solid var(--main-color);
    border-radius: 2px;
    color: var(--main-color);
    font-size: var(--label-size);
    cursor: pointer;
}

/* stock logs */
.logsContentWrapper {
    display: flex;

}


/* step location tab */
.variantTabcon {
    width: 20%;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

}

.variantItem {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 0px 20px;
    line-height: 38px;
    background-color: var(--light-background-color);
    text-decoration: none;
    color: var(--font-color);

}

.variantItemActive {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 0px 20px;
    line-height: 38px;
    text-decoration: none;
    color: var(--main-color);
    background-color: var(--light-background-color);
    border-left: 2px solid var(--main-color);

}

.variantItem:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--main-color);
}

.logsContentCon {
    width: 100%;
}

.productName {
   margin-top: 12px;
   font-weight: 700;
}


/* export  and import */
.contentWrapper {
    margin: 20px;
}
.fileName {
    color: var(--main-color);
    display: flex;
    align-items: center;
  }
.csvLogo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: var(--edit-btn);
}
.importTips {
    display: flex;
    margin: 12px 0px;
}
.xeroTips {
    font-size: var(--label-size);
    color: var(--delete-btn);
   
}
/* bulk stock adjustment */


.adjustQtyCon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.syncXeroBtn {
    border: 1px solid var(--xero-blue);
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}
.syncXeroBtn:hover {
    background-color: var(--xero-blue);
    color: white;
    cursor: pointer;
}





