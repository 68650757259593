/* Popup window can be used whole site */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--popup-overlay);
    z-index: 2;
}

.inputModal {
    width: 50%;
    max-height: 70%;
    position: absolute;
    /* viewport 50% */
    left: 50%;
    top: 20%;
    margin-left: -25%;
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: none;
    overflow: auto;
}

.contentModal {
    width: 70%;
    max-height: 70%;
    position: absolute;
    /* viewport 50% */
    left: 50%;
    top: 20%;
    margin-left: -30%;
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: none;
    overflow: auto;
}

.fullHeightModal {
    width: 50%;
    height: 85%;
    position: absolute;
    /* viewport 50% */
    left: 50%;
    top: 8%;
    margin-left: -25%;
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: none;
    overflow: auto;
}

.fullHeightContentModal {
    width: 70%;
    height: 85%;
    position: absolute;
    /* viewport 50% */
    left: 50%;
    top: 8%;
    margin-left: -30%;
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: none;
    overflow: auto;
}
.fullWidthContentModal {
    width: 95%;
    height: 95%;
    position: absolute;
    left: 2.5%;
    top: 2.5%;
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: none;
    overflow: auto;
}

.fullContentModal {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: none;
    overflow: auto;
}

.modalColorBg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-background-color);

}
.modalColorBgCircle1 {
    position: absolute;
    width: 400px;
    height: 400px;
    bottom: 5%;
    right: 50%;

    border-radius: 200px;
    background-color: var(--main-color);
    filter:blur(120px);
    background: radial-gradient(circle at 50% 50%, #4683FF, #444bf700);
    opacity: 0.7;

}
.modalColorBgCircle2 {
    position: absolute;
    width: 600px;
    height: 600px;
    top: 5%;
    right: 5%;
    border-radius: 200px;
    background-color: var(--main-color);
    filter:blur(120px);
    background: radial-gradient(circle at 50% 50%, #4683FF, #444bf700);
    opacity: 0.7;

}
.modalColorBgCircle3 {
    position: absolute;
    width: 600px;
    height: 200px;
    top: 0;
    left: 5%;
    border-radius: 200px;
    background-color: var(--main-color);
    filter:blur(80px);
    background: radial-gradient(circle at 50% 50%, #444bf7, #444bf700);
    opacity: 0.6;

}
.modalColorBgCircle4 {
    position: absolute;
    width: 600px;
    height: 600px;
    bottom: 5%;
    right: 0;
    border-radius: 200px;
    background-color: var(--main-color);
    filter:blur(120px);
    background: radial-gradient(circle at 50% 50%, #444bf7, #444bf700);
    opacity: 0.7;

}

.onboardingContentContainer {
    position: relative;
    z-index: 2;
    width: 70%;
    min-height: 50%;
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    border-radius: 6px;
    padding: 24px 48px;
    font-size: 14px;
}
.modalLogoWrapper{
    height: 20%;
    display: flex;
    align-items: start;
    justify-content: space-between;

}
.modalLogoCon {
    width: 120px;
    height: 28px;
    text-decoration: none;
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/stockunify-logo-black.svg");
    background-repeat: no-repeat;
    background-position: 80% top;
    background-size: 100%;
}
.skipButton {
    background-color: transparent; 
    border: none;
    color: var(--gray-color);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
}
.skipButton:hover {
    color: var(--main-color);
    text-decoration: underline;
    cursor: pointer;
}
.modalMainContentCon {
    height: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
}
.taskItem {
    margin-top: 16px;
    border: 1px solid var(--border-color);
    width: 100%;
    border-radius: 6px;
    padding: 0 16px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.taskActionItem {
    margin-top: 16px;
    border: 1px solid var(--border-color);
    width: 100%;
    border-radius: 6px;
    padding: 0 16px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.taskItemTitle {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;  
}
.taskIcon {
    color: var(--main-color);
    width: 24px;
    height: 24px;
    margin-right: 4px;
}
.taskActionBtn {
    background-color: transparent; 
    border: none;
    color: var(--gray-color);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-top: 6px;
}
.taskActionBtn:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--main-color);
}



.modalContentContainer {
    width: 100%;
    margin: auto;
    padding-bottom: 10px;

}

.modalTitle {
    padding: 20px 20px;
    box-shadow: var(--box-shadow);
    display: flex;
    justify-content: space-between;
}



.modalContent {
    width: 50%;
    font-size: var(--main-size);
    align-items: center;
    justify-content: center;
    margin: 12px auto;
    
}

.modalContent_full {
    font-size: var(--main-size);
    align-items: center;
    justify-content: center;
    margin: 0 20px;
}


.closeIconContainer {
    display: flex;
    justify-content: flex-end;
    align-self: right;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    /* background-color: var(--light-background-color); */
}

.closeIcon:hover {
    background-color: var(--border-color);
    box-shadow: var(--box-shadow-dark);

}

.closeIcon {
    width: 28px;
    height: 28px;
    color: var(--gray-color);
    background-color: var(--light-background-color);
}




/* loader style css */
.loader {
    display: flex;
    align-items: center;
    margin: 20px 20px;
    font-size: var(--main-size);
}

/* error message style css */
.fadeErrorContainer{
    display: flex;
    align-items: center;
    padding: 20px 10px;
    background-color: var(--light-red-background-color);
    color: var(--delete-btn);
    border-radius: 2px;
    font-size: var(--main-size);
}

.fadeErrorContainer.hide{
    display: none;
}

.errorMesContainer {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    background-color: var(--light-red-background-color);
    color: var(--delete-btn);
    border-radius: 2px;
    font-size: var(--main-size);
}

.loaderMessage,
.errorMessage {
    margin-left: 4px;
}

.errorMessageText {
    margin-top: 2px;
    color: var(--delete-btn);
}

.successMessageText {
    margin-top: 2px;
    color: var(--edit-btn);
}

.roleTag {
    border: 1px solid var(--edit-btn);
    padding: 4px 8px;
    margin: 2px 4px 2px 2px;
    border-radius: 10px;
    color: var(--edit-btn);
    font-size: var(--tag-size);
}

.viewTextCon {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    /* background-color: antiquewhite; */
}

.viewTextTitle {
    color: var(--input-label);
    font-size: var(--label-size);
    margin: 10px 0 4px 0px;
}


.textValue {
    size: var(--label-size);
}

.nullValue {
    size: var(--label-size);
    color: var(--border-color);
}

.passwordHints {
    display: block;
    margin: 2px 0 12px 0;

}

.hintPassed {
    display: flex;
    color: var(--edit-btn);
    margin-top: 2px;
    font-size: var(--label-size);
}

.hintError {
    display: flex;
    color: var(--gray-color);
    margin-top: 2px;
    font-size: var(--label-size);
}

/* simple dialog style css */
.dialogContentContainer {
    padding: 20px;
}
.dialogTitle {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.dialogSuccessMessage {
    font-size: var(--main-size);
    padding: 20px 10px;
    color: var(--edit-btn);
    background-color:var(--light-green-background-color);
    border-radius: 2px;
}

.confirmBtnCon {
    display: flex;
}
.confirmBtn{
    margin: 12px 0 0 0;
    padding: 0 20px;
    height: 32px;
    border: 1px solid var(--main-color);
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: var(--label-size);
    cursor: pointer;
}

.confirmBtn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.xeroLinkBtn{
    margin: 12px 0 0 0;
    padding: 0 20px;
    height: 32px;
    border: 1px solid var(--xero-blue);
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: var(--label-size);
    cursor: pointer;
}

.xeroLinkBtn:hover {
    background-color: var(--xero-blue);
    color: var(--white-color);
}


/*delete dialog*/


.delBtnCon {
    display: flex;
}


.delBtn,
.cancelBtn {
    margin: 12px 0px 0px 0px;
    height: 32px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: var(--label-size);
    cursor: pointer;
}

.delBtn {
    border: 1px solid var(--delete-btn);
    color: var(--delete-btn);
}
.delBtn:hover {
    background-color: var(--delete-btn);
    color: var(--white-color);
}


.cancelBtn {
    border: 1px solid var(--main-color);
    margin-left: 12px;
}
.cancelBtn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

/*proceed dialog*/
.preDelMess {
    font-size: var(--main-size);
    padding: 20px 10px;
    color: var(--delete-btn);
    background-color:var(--light-red-background-color);
    border-radius: 2px;

}




/*****************/

.btnCon {
    display: flex;
}

.fromTag {
    font-size: var(--tag-size);
    color: var(--gray-color);
}




.tagCon {
    display: inline-block;
    font-size: var(--tag-size);
    padding: 4px 6px;
    margin: 0 2px;
    text-align: center;
    border-radius: 10px;
}

.greenTag {
    background-color: var(--edit-btn);
    color: var(--white-color);
}
.grayTag {
    background-color: var(--light-gray-bc);
    color: var(--font-color);
}
.yellowTag {
    background-color: var(--default-label);
    color: var(--white-color);
}

.blueTag {
    background-color: var(--slight-blue);
    color: var(--main-color);
}
.brandBlueTag {
    background-color: var(--main-color);
    color: var(--white-color);
}
.redTag {
    background-color: var(--light-red-background-color);
    color: var(--delete-btn);
}
.lightGreenTag {
    background-color: var(--light-green-background-color);
    color: var(--edit-btn);
}

.variantTag {
    display: inline-block;
    font-size: var(--tag-size);
    padding: 4px 6px;
    margin: 0 2px;
    text-align: center;
    border-radius: 10px;
    background-color: var(--slight-blue);
    color: var(--main-color);
}

.variantSkuTag {
    display: inline-block;
    font-size: var(--main-size);
    padding: 0 4px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid var(--gray-color);
    color: var(--gray-color);
}


.subtitle {
    margin: 12px 0px 0px 0px;
    font-size: var(--main-size);
    color: #73A1FF;
}

.hightlightLetters {
    color: var(--main-color);
}

/* style for receive stock dialog */
.partialtext {
    font-size: var(--label-size);
    color: var(--delete-btn);
    background-color: var(--light-red-background-color);
    padding: 6px 2px;
    
}

.textLabel {
    margin: 10px 0px;
    font-size: var(--label-size);
    color: var(--input-label);
}

.splitHinText {
    font-size: var(--label-size);
    color: var(--main-color);
    padding-left: 26px;
    margin: 4px 0px 10px 0px;
}

.proceedActionsCon {
    display: flex ;
    color: var(--main-color);
    font-size: var(--label-size);
    margin-top: 10px;
}

.tipsText {
    color: var(--gray-color);
    font-size: var(--label-size);
    margin: 10px 0px;
}

.viewPreText {
    font-family: inherit;
    color: var(--font-color);
    /* font-size: var(--label-size); */
}

.questionMarkCon {
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    display: flex;
    align-items: center;
}



.questionMarkText {
    display: none;
    width: 100%;
    position: absolute;
    top: 22px;
    right: 0px;
    font-size: var(--label-size);
    padding: 10px 2px;
    color: var(--white-color);
    background-color: var(--hover-title);
    border-radius: 2px;
    z-index: 10;
}

.questionMarkCon:hover .questionMarkText {
    display: block;
}

.xeroLinkCon {
    display: flex;
    align-items: center;
    font-size: var(--label-size);
}

.xeroLogo {
    margin-right: 6px;
    width: 22px;
    height: 22px;
    background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/xero_logo_blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.xeroLinkCon:hover {
    text-decoration: underline;
    cursor: pointer;
}

.imageContainer {
    width: 100%;
    height: 80%;
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.productImage {
    width: 80%;
    height: 80%;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    overflow-y: hidden;
    justify-content: center;
}

.productImagePreview {
    width:100%;
    height: 100%;
    object-fit: contain;
}

.defaultImage {
    width: 40%;
    height: 40%;
    color: var(--border-color);
}

.termLink {
    margin-top: 10px;
    margin-left: 2px;
    color: var(--main-color);
    text-decoration: none;
  
  }
  .termLink:hover {
    text-decoration: underline;
  
  }

  .moreLink {
    text-decoration: underline;
  }
  .moreLink:hover {
    color: var(--main-color);
    
  }





