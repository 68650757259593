.uploadForm {
    height: 12rem;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }
  
  .inputField {
    display: none;
  }
  
  .inputFieldLabel {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--light-blue);
    border-radius: 8px;
  }

  .inputFieldLabel.dragActive {
    border: 2px dashed var(--main-color);
  }
  
  .uploadTips {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .uploadImg {
    width: 60px;
    height: 60px;
    color: var(--light-blue);
    margin-bottom: 20px;
  }
  .browse {
    color: var(--main-color);
    font-weight: 700;
    margin-left: 2px;
  }

  .fileCon {
    padding: 20px;

  }

  .fileName {
    color: var(--main-color);
  }
  .successFileTextRow {
    display: flex;
    color: var(--edit-btn);
  }

  .fileRow {
    margin-top: 12px;
    display: flex;
    padding: 20px; 
    border: 1px dashed var(--main-color);
    align-items: center;
  }

  .csvIcon {
    width: 24px;
    height: 24px;
    color: var(--invoice-blue);
    margin-right: 10px;
  }
  
 