

th.priceTierHeader {
    padding: 10px 0;
    text-align: center;
    border-left: 2px dashed var(--border-color);
    width: 200px;
}


.priceTierContent {
    border-left: 2px dashed var(--border-color);
    width: 240px;
}
.priceTierPriceCol {
    border-left: 2px dashed var(--border-color);
    background-color: var(--light-green-background-color);
    width: 240px;
    padding: 0 16px
}

.priceTypeCBtn {
    padding: 4px 2px;
    /* background-color: var(--main-color); */
    font-size: var(--tag-size);
    color: var(--label-color);
    border-radius: 2px;
    border: 1px solid var(--border-color);
    cursor: pointer;
}
.priceTypeCBtnActive {
    padding: 4px 2px;
    font-size: var(--tag-size);
    color: var(--main-color);
    border-radius: 2px;
    border: 1px solid var(--main-color);
    cursor: pointer;
}

.discountCon {
    display: flex;
    align-items: flex-start;
}
.priceTypeCon {
    display: flex;
    margin: 10px 10px 0 10px;
    font-size: var(--label-size);
    align-items: center;
    justify-content: flex-start;

}

.markupPriceCon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.markupPriceText {
    width: 50%;
    font-size: var(--label-size);
    text-align: center;
    margin: 0 10px;
    border-left: 1px solid var(--border-color);
    padding-left: 4px;
    display: flex;
}

.bulkFillBtn {
    display:flex;
    align-items: center;
    justify-content: center;
    color: var(--edit-btn);
    font-size: var(--label-size);
    margin-left: 10px;
    cursor: pointer;
}

.bulkTips {
    margin-top: 10px;
    font-size: var(--label-size);
    color: var(--delete-btn);
}

.btnCon {
    padding: 5px 25%;
}

.headerTitle {
   box-sizing: border-box;
    width: 120px;
}
.headerHalfTitle{
    box-sizing: border-box;
    width: 60px;
}
