

/* export  and import */
.contentWrapper {
    margin: 20px;
}
.fileName {
    color: var(--main-color);
    display: flex;
    align-items: center;
  }
.csvLogo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: var(--edit-btn);
}
.importTips {
    display: flex;
    margin: 12px 0px;
}

.downloadBtnCon {
    width: 60%;
    background-color: var(--main-color);
    color: var(--white-color);
    padding: 10px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    text-decoration: none;
}

.downloadBtnLogo {
    margin-right: 4px;
}

.downloadBtnCon:hover{
    cursor: pointer;
    background-color: var(--main-hover);
}





