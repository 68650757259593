

/* users & roles CSS */

.rolesContent {
    /* height: 100%; */
    display: flex;
    margin: 20px 20px;

}

.rolesList {
    min-width: 600px;

}

.listTitle {
    display: flex;
    justify-content: flex-end;
}

.roleView {
    width: 100%;
}

.previewCon {
    height: 100%;
    padding: 10px;
    /* margin-top: 10px; */
    background-color: var(--light-background-color);
    border-radius: 2px;

}

.halfPreviewCon {
    width: 50%;
    height: 100%;
    padding: 10px;
    /* margin-top: 10px; */
    background-color: var(--light-background-color);
    border-radius: 2px;

}


.roleName {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.roleNameEdit {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.permissions {
    margin-top: 20px;
  
}

.categoryName {
    background-color: var(--light-background-color);
    padding: 8px 10px;
}

.optionCon {
    margin: 12px 0px 12px 0px;
    display: flex;
    /* align-items: center; */
    align-items: flex-start;
    flex-wrap: wrap;
    /* background-color: var( --light-blue-color); */
    background-color: var(--white-color);
    padding: 10px 0px 10px 10px;

}

.optionEdit {
    width: 260px;
    display: flex;
    align-items: center;
    margin: 2px 20px 6px 0px;
}

.optionEdit:hover {
    color: var(--main-color);
}


.optionTrue {
    width: 260px;
    display: flex;
    margin: 12px 20px 0px 0px;
    align-items: flex-start;
}

.optionFalse {
    width: 260px;
    display: flex;
    margin: 12px 20px 0px 0px;
    align-items: flex-start;
    color: var(--arrow-color);
}

.hasPermission {
    min-width: 14px;
    min-height: 14px;
    color: var(--edit-btn);
    margin-right: 2px;
}

.noPermission {
    min-width: 14px;
    min-height: 14px;
    color: var(--delete-btn);
    margin-right: 2px;
}

.categoryNameCon {
    display: flex;
    margin: 10px 0px;
    align-items: center;
    /* border-bottom: 1px solid var(--light-background-color); */

}

.categoryNameIcon {
    margin-right: 2px;


}

.editBtn {
    position: relative;
    cursor: pointer;
}

.roleTag {
    border: 1px solid var(--edit-btn);
    padding: 2px 4px;
    margin: 2px 4px 2px 2px;
    border-radius: 2px;
    color: var(--edit-btn);
}

/* user detai form */

.col1,
.col2 {
    flex: 1;
    min-width: 300px;
}

.col1 {
    margin-right: 20px;
}


/* user account page css */
.accountCon {
    /* border: 1px dashed var(--main-color); */
    padding-top: 1px;
    margin: 20px;
    background-color: var(--light-background-color);
}
.detailCon {
    /* width: 100%; */
    margin: 20px 20px;
    /* box-shadow: var(--box-shadow); */
    border-radius: 2px;
    padding: 20px 20px;
    background-color: var(--white-color);

    /* color: white; */
    /* border: 1px solid #90B5FE; */
}

.infoCon {
    margin-top: 10px;
    padding: 0 10px 0px 10px;
    display: flex;

}

.changepass {
    padding: 0 10px 0px 0px;
    display: flex;
    color: var(--main-color);
  
}

.changepass:hover {
    text-decoration: underline;
    cursor: pointer;
}

.rightEditBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.templatesContentWrapper {
    flex: 1;
    margin-top: 10px;
    padding: 0px 20px;
    /* background-color: var(--light-background-color); */
}
.templatesTitleCon {
    display: flex;
    align-items: center;

}
.templatesConten{
    margin-top: 12px;
    /* width: 60%; */
    min-height: 400px;
    background-color: var(--light-background-color);
    /* border: 1px solid var(--main-color); */
    border-radius: 4px;
    padding: 12px;


}
.templatePreviewCon {
    background-color: #fff;
}

/* company settings css */
.companySettingsCon {
    width: 100%;
}

.subTitleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    height: 38px;
}


.contentCon {
    width: 100%;
    margin: 20px;
    display: flex;
}


.logoContainer {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 120px;
    height: 120px;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    overflow-y: hidden;
    justify-content: center;
}

.defaultLogo {
    width: 40px;
    height: 40px;
    color: var(--border-color);
}

.uploadBtn {
    margin-top: 20px;
    line-height: 32px;
    padding: 0px 12px;
    border: 1px solid var(--main-color);
    background-color: var(--white-color);
    color: var(--main-color);
    border-radius: 4px;
    cursor: pointer;
    border-radius: 4px;
}


.uploadBtn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    box-shadow: var(--box-shadow);
}

.uploadBtn input {
    display: none;
}


.tableCon {
    width: 50%;
}

.textTable {
    width: 100%;
}

.textTable tr {
    height: 32px;
}

.textTable td {
    padding-left: 20px;
    text-align: left;
    vertical-align: middle;
}

.textTable tr .valueField {
    width: 70%;
}


.textTable .title {
    color: var(--input-label);

}