



.section_wrapper {
    box-shadow: var(--box-shadow-card);
    margin: 20px;
    padding: 20px;
   border-radius: 8px;
   box-sizing: border-box;
}

.section_titleCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reportLink {
    text-decoration: none;
    padding: 8px 0;
    display: block;
    color: var(--main-color);
    margin-right: 6px;

}

.reportLink:hover {
    text-decoration: underline;
}

.reportNameLink {
    text-decoration: none;
    padding: 8px 0;
    display: block;
    margin-right: 6px;

}

.reportNameLink:hover {
    text-decoration: underline;
}

