.serchCon {
    width: 100%;
    margin-right: 20px;
    position: relative;
}

.searchResultsCon {
    position: absolute;
    top: 48px;
    width: 100%;
    z-index: 2;
    overflow-y: auto;
    box-shadow: var(--box-shadow-card);
}

.searchResultsProductCon {
    width: 100%;
    border: 1px solid var(--light-background-color);
    box-shadow: var(--box-shadow-card);
    background-color: var(--light-background-color);
    min-height: 280px;
    max-height: 560px;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    box-sizing: border-box;
}

.noResultLine {
    padding: 12px 10px;
    color: var(--delete-btn);
    position: relative;
}

.searchResultLine {
    padding: 20px 20px;
    border: 1px solid var(--light-blue);
    background-color: var(--white-color);
    position: relative;
    font-weight: 500;
    margin: 12px 0;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;

}


.searchResultLine:hover {
   font-weight: 700;
    cursor: pointer;
    color: var(--main-color);
}
.proTypeTag {
    padding: 4px;
    margin-right: 12px;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    border-radius: 4px;
}






.attrName {
    /* font-size: var(--label-size); */
    color: var(--input-label);
    margin-top: 20px
}


.optionsCon {
    margin-top: 12px;
    display: flex;
}

.optionNameUnselected:hover,
.optionNameSelected {
    color: var(--white-color);
    font-size: var(--label-size);
    border: 1px solid var(--main-color);
    background-color: var(--main-color);
    border-radius:20px;
    margin-right: 12px;
    padding: 10px 12px;
}

.optionNameUnselected {
    font-size: var(--label-size);
    border: 1px solid var(--main-color);
    border-radius:20px;
    margin-right: 12px;
    padding: 10px 12px;
}

.selectOptionBtn {
    margin-top: 10px;
    padding: 10px 20px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    color: var(--border-color);
    font-size: var(--label-size);
    width: fit-content;

}

.selectOptionBtnActive:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}


.selectOptionBtnActive {
    margin-top: 10px;
    padding: 10px 20px;
    border: 1px solid var(--main-color);
    border-radius: 2px;
    color: var(--main-color);
    font-size: var(--label-size);
    width: fit-content;
}


.categorySearcBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border: 1px solid var(--main-color);
    padding: 0 12px;
    border-radius: 4px;
    color: var(--main-color);
    box-sizing: border-box;


}

.categorySearcBtn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    cursor: pointer;
}

.checkIconChecked {
    width: 26px;
    height: 26px;
    color: var(--main-color);

}
.checkIconUnChecked {
    width: 26px;
    height: 26px;
    color: var(--border-color);

}

.checkIconUnChecked:hover,
.checkIconChecked:hover {
    cursor: pointer;
}

.packageSelectCon {

}

.packageSelectCon ::placeholder {
    display: block;
    color: var(--main-color);
}
