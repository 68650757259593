

/* user account page css */
.accountCon {
    padding-top: 1px;
    margin: 20px 20px;
    background-color: var(--light-background-color);
}
.detailCon {
    margin: 20px 20px 20px 20px;
    border-radius: 2px;
    padding: 20px 20px;
    background-color: var(--white-color);

}

.infoCon {
    margin-top: 10px;
    padding: 0 10px 0px 10px;
    display: flex;

}

.changepass {
    padding: 0 10px 0px 0px;
    display: flex;
    color: var(--main-color);
  
}

.changepass:hover {
    text-decoration: underline;
    cursor: pointer;
}

.rightEditBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

/* manage subscription css */
.subscripitionCon {
    margin-top: 10px;
    padding: 20px;

}

.planTitle {
    color: var(--main-color);
}


.userNum {
    color:var(--main-color);

}