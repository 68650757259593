/* over write date picker css */

/* change date input width and height */
.react-datepicker__input-container {
    width: 100%;
   
}
.react-datepicker__input-container input {
   
    width: 100%;
    height: 36px;
    border: none;
    padding-left: 10px;
    color: var(--font-color);
    background-color: var(--white-color);
    border-radius: 4px;
    cursor: pointer;


}
.react-datepicker__input-container input:hover,
.react-datepicker__input-container input:focus {
    outline: none;
}

.react-datepicker__input-container input::placeholder {
    color: #8F8F8F;
    font-size: 0.725rem;
}

/* change date picker header bc color */
.react-datepicker {
    box-shadow: var(--box-shadow);
    border: none;

}
.react-datepicker__header {
    text-align: center;
    background-color: var(--light-background-color);
    border-bottom: none;
    border-top-left-radius: 2px;
    padding: 8px 0;
    position: relative;
  }

  
  .react-datepicker__today-button {
    background-color: var(--main-color);
    border-top: 0px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }




.react-datepicker__day--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--selected {
    background-color: var(--main-color);
}
