/* hold all content except header and footer */
.mainContentContainer {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 50px);
    color: var(--font-color);
    /* =13px */
    font-size: var(--main-size);
    position: relative;
  
}


/* page container  except sidebar*/
.pageContainer {
    min-width: 600px;
    height: inherit;
    flex: 1;
    background-color: var(--white-color);
    margin-top: 50px;
    overflow: auto;
    box-sizing: border-box;
}


/* hold all the content except page title */
.pageContentContainer {
    overflow-y: auto;
    margin: 20px 20px;
    padding: 0px 20px;
    overflow-x: auto;
    box-sizing: border-box;
    min-height: 1200px;
}

.pageContentContainerTwoColumn{
    display: flex;
    box-sizing: border-box;
    overflow-y: auto;
    margin-top: 20px;
    /* margin: 20px 20px; */
    /* padding: 0px 20px; */
    overflow-x: auto;
   

}

.templatesContentWrapper {
    width: 100%;
}

.pageCardContentContainer {
    overflow-y: auto;
    overflow-x: auto;
}

.previewContentContainer {
    background-color: var(--light-background-color);
    overflow-y: auto;
    overflow-x: auto;
    margin-top: 12px;
    padding: 20px 20px;

}


/* table container for short data display*/
.shortDataTable {
    width: 60%;
    min-width: 40%;
}

/* table container for short data display*/
.longDataTable {
    min-width: 80%;
}

.longDataTableContainer {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
.tableWrapper {
    min-width: 1400px;
}

/* table style can be used whole site*/
.pageTable {
    margin-top: 12px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--border-color);
    border-radius: 8px;
}


.summaryTable {
    margin-top: 0px;
    width: 100%;
    border-collapse: collapse;
    background-color: var(--light-background-color);
}


.pageTable th {
    background-color: var(--light-background-color);
    height: 38px;

}

.pageTable th,
.pageTable tbody td {
    padding-left: 4px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid var(--border-color);
}


.summaryTable tbody td {
    padding-left: 4px;
    text-align: left;
    vertical-align: middle;
}

.pageTable tr,
.summaryTable tr {
    height: 46px;
}
.pageTable tbody tr:hover,
.summaryTable tbody tr:hover {
    background-color: var(--table-hover);
}

.summaryTable tr .name:hover,
.pageTable tr .name:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--main-color);
}


.summaryTable tbody td.tableFootName {
    padding-right: 10px;
    vertical-align: middle;
    text-align: right;
    font-weight: 600;
}

.summaryTable tbody tr.summaryTableTotalRow {
    border-top: 1px solid var(--border-color);
}




.summaryTable tbody td.summaryTableTotalName {
    font-size: 1rem;
    padding-right: 10px;
    vertical-align: middle;
    text-align: right;
    font-weight: 600;
    color: var(--main-color);
}

.summaryTable tbody td.summaryTableTotalValue {
    font-size: 1rem;
    /* padding-right: 10px; */
    text-align: left;
    color: var(--main-color);
}

/* tfoot style */
.pageTable tfoot td {
    padding-left: 4px;
    text-align: left;
    vertical-align: middle;
}

.pageTable tfoot td.tableFootName {
    padding-right: 10px;
    vertical-align: middle;
    text-align: right;
    font-weight: 600;
}

.logsTable th.stickyColumn,
.logsTable td.stickyColumn,
.pageTable th.stickyColumn,
.pageTable td.stickyColumn {
  position: sticky;
  left: 0;
  z-index: 2;
}


.pageTable th.stickyColumn:nth-child(2),
.pageTable td.stickyColumn:nth-child(2) {
  left: 100px; /* Adjust based on the width of the first column */
  z-index: 3; /* Ensure the second column is on top of the first */
}

.logsTable th.stickyColumn:nth-child(2),
.logsTable td.stickyColumn:nth-child(2) 
{
  left: 180px; /* Adjust based on the width of the first column */
  z-index: 3; /* Ensure the second column is on top of the first */
}

.pageTable td.stickyColumn {
  background-color: white; /* Default: no background */
}

.logsTable td.stickyColumn,
.logsTable th.stickyColumn {
  background-color: white; /* Default: no background */
}

.pageTable tbody tr:hover td.stickyColumn {
    background-color: var(--table-hover); /* Apply hover color to sticky columns */
}


.taxText {
    font-size: var(--label-size);
    color: var(--gray-color);
}

.tableError {
    font-size: var(--label-size);
    margin: 4px 0px;
    border-radius: 2px;
    padding: 10px 0px 10px 3.2%;
    color: var(--delete-btn);
    background-color: var(--light-red-background-color);
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/error_icon.svg");
    background-repeat: no-repeat;
    background-position: 1% center;
}

.textError {
    font-size: var(--label-size);
    margin: 4px 0px;
    padding: 10px 0px 10px 3.2%;
    color: var(--delete-btn);
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/error_icon.svg");
    background-repeat: no-repeat;
    background-position: 0 center;
}



/* 
/* set different color to odd or even row */
/* .pageTable tr:nth-child(even) {
    background-color: var(--light-background-color);

}
.pageTable tr:nth-child(odd) {
    background-color: var(--white-color);
} */ 

/* actions container can be used whole site */
.actionsGrop {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
}


/* breadcrumbs CSS */
.breadcrumbsContainer {
    padding: 10px 0 0 20px;
    background-color: var(--white-color);

}

.breadcrumbsContainer {
    display: flex;
    /* flex-direction: row; */
}
.breadcrumbsFirstItem {
    color: var(--gray-color);
    margin-right: 6px;
}
.breadcrumbsItem {
    margin-right: 6px;
    text-decoration: none;
    color: var(--gray-color);
    transition: text-decoration 0.3s;
}

.breadcrumbsLastItem {
    color: var(--main-color);
}

.breadcrumbsItem:hover {
    text-decoration: underline;
    color: var(--main-color);
}

.breadcrumbsItem:after:hover {
    text-decoration: none;
}

.breadcrumbsFirstItem:after,
.breadcrumbsItem:after {
    content: "/";
    margin-left: 6px;
    display: inline-block;

}


.breadcrumbsItem:last-child:after {
    display: none;

}

.breadcrumbsItem:last-child {
    color: var(--main-color);

}


/* Pagination style */
.paginationCon {
    display: flex;
    height: 48px;
    align-items: center;
}

.pageItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: var(--light-background-color);
    margin-right: 10px;
    border-radius: 2px;
    color: var(--gray-color);

}

.pageItemActive {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: var(--main-color);
    margin-right: 10px;
    border-radius: 2px;
    color: var(--white-color);
 

}

.pageItem:hover {
    cursor: pointer;
}

/* button container */

.btnWrapper {
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnCon {

 width: 100%;
    align-items: center;
    display: flex;
}
.saveHintText {
    margin-bottom: 20px;
    color: var(--gray-color);
    font-size: var(--label-size);
}

/* three col preview css */
.previewWrapper {
    display: flex;
    justify-content: space-between;
}

.col1,
.col2,
.col3 {
    flex: 1;

}


/* logs table */
.logsTable{
    margin-top: 12px;
    width: 100%;
    border-collapse: collapse;
    /* border: 1px solid var(--border-color); */
}

.logsTable th {
    height: 38px;
    text-align: center;
}

.logsTable th,
.logsTable tbody td {
    font-size: var(--label-size);
    padding-left: 4px;
    text-align: left;
    vertical-align: middle;
    /* border-bottom: 1px solid var(--border-color); */
}



 /* page tabs CSS; */

 .tabsContainer {
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    margin: 20px 20px;
    position: relative;
}

.tabsCon {
    display: flex;
    position: absolute;
    height: 38px;
    z-index: 1;
}

.tabItem {
    margin-right: 10px;
    padding: 0px 20px;
    line-height: 38px;
    border-bottom: 0.0625rem solid var(--main-color);
    background-color: var(--light-background-color);
    margin-bottom: -0.125rem;
    text-decoration: none;
    color: var(--font-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    /* background-color: red; */
}

.activeItem {
    margin-right: 10px;
    padding: 0px 20px;
    /* box-shadow: 1px -2px 3px 0px rgba(3, 2, 2, 0.06); */
    line-height: 38px;
    border-top: 0.0625rem solid var(--main-color);
    border-left: 0.0625rem solid var(--main-color);
    border-right: 0.0625rem solid var(--main-color);
    background-color: var(--white-color);
    margin-bottom: -0.125rem;
    text-decoration: none;
    color: var(--font-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.tabItem:hover,
.activeItem:hover {
    text-decoration: underline;
    color: var(--main-color);
    cursor: pointer;
}

.settingsContent {
    margin-top: 39px;
    position: relative;
    flex: 1;
    border-top: 0.0625rem solid var(--main-color);
    box-shadow: 1px 2px 10px 0px rgba(3, 2, 2, 0.06);
   
}

/* vertical tabs css */
.variantTabcon {
    width: 20%;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

}

.variantItem {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 0px 20px;
    line-height: 38px;
    background-color: var(--light-background-color);
    text-decoration: none;
    color: var(--font-color);

}

.variantItemActive {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 0px 0px 0px 4px;
    line-height: 38px;
    text-decoration: none;
    color: var(--main-color);
    background-color: var(--light-background-color);
    border-left: 2px solid var(--main-color);

}

.variantItem:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--main-color);
}

.divider {
    border-bottom: 1px solid var(--border-color);
    margin: 10px 0px;
}



/* scan form css */

.scanWrapper {
    padding: 20px;
    /* background-color: var(--light-background-color); */

}

.scanWrapper:focus {
    border: 1px solid var(--main-color);
}

.readyScanCon {
    margin: 20px 0px;
    padding: 20px;
    text-align: center;
    border: 1px dashed var(--edit-btn);
    background-color: var(--light-green-background-color);
}
.lostFocusScanCon {
    margin: 20px 0px;
    padding: 20px;
    text-align: center;
    border: 1px dashed var(--main-color);
    background-color: var(--light-background-color);
}
.lostFocusScanCon:hover {
    cursor: pointer;
    background-color: var(--main-color);
    color: var(--white-color);
}

.stockTag {
    background-color: var( --light-green-background-color);
    color: var(--edit-btn);
    font-size: var(--tag-size);
    padding: 0px 2px;
}
.noStockTag {
    background-color: var( --light-red-background-color);
    color: var(--delete-btn);
    font-size: var(--tag-size);
    padding: 0px 2px;
}


/*no permisson page*/
.nopermissonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
   
}

.nopermissonCon {
    width: 600px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/not_found_icon.svg");
    background-repeat: no-repeat;
    background-position: 80% top;
    background-size: 100%;
}
.nopermissonIconCon {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    background-color: var(--light-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.nopermissonIcon {
    width: 60px;
    height: 60px;
    color: var(--gray-color);

}

.brandLogoCon {
    /* background-color: blue; */
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100px;
    padding: 20px 40px 0px 0px;
    box-sizing: border-box;
}

.brandLogo {
    width: 160px;
    height: 40px;
    text-decoration: none;
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/stockunify-logo-black.svg");
    background-repeat: no-repeat;
    background-position: 80% top;
    background-size: 100%;
}
.textLine {
    margin-top: 12px;
}
.productTypeIconCon {
    display: flex;
    align-items: center;
}
.productTypeIcon {
    margin-right: 6px;
    width: 24px;
    height: 24px;
    background-image: url("../icons/product_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.rentalTypeIcon {
    margin-right: 6px;
    width: 24px;
    height: 24px;
    background-image: url("../icons/rental_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.serviceTypeIcon {
    margin-right: 6px;
    width: 24px;
    height: 24px;
    background-image: url("../icons/service_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.prodyctTypeTag {
    font-size: var(--tag-size);
}
.serialNumberCon {
    margin-top: 12px;
    width: fit-content;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid var(--main-color);
    margin-right: 20px;
}
.selecteTrackingNumberBtn {
    color: var(--main-color);
    margin-bottom: 6px;
}
.selecteTrackingNumberBtn:hover {
    cursor: pointer;
}

.insufficientNum {
    color: var(--delete-btn);
}

.sufficientNum {
    color: var(--edit-btn);
}