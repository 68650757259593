
.ruiInventoryHeader {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--header-background-color);
    height: 50px;
    box-shadow: var(--box-shadow);
    font-size: var(--main-size);
    z-index: 1; 
}

.brandLogo {
    width: 120px;
    height: 46px;
    margin: 2px 20px;
    justify-content: center;
    align-items: center;
    background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/stockunify-logo-white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.link,
.sublink {
    text-decoration: none;
    color: inherit;
}

.logo {
    text-decoration: none;
    color: var(--white-color);
}



/* Dropdown MENU */
.dropDownMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    color: var(--white-color);
}

.accountIcon {
    margin-left: 4px;
    width: 16px;
    height: 16px;
}

.dropdownItem {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
}



.dropdownItem:hover {
    color: var(--main-color);
    background: var(--light-background-color);

}

.dropdownItem {
    position: relative;
}


/* DROPDOWN MENU */

.dropdownContent {
    display: none;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    top: 50px;
    right: 10px;
    min-width: 260px;
    box-shadow: -6px 10px 18px -2px rgba(0, 0, 0, 0.1);
    background-color: var(--white-color);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: var(--font-color);
    padding: 10px 0;


}

/* show dropdown menu when hover on or click */
.dropdownItem.open .dropdownContent,
.dropdownItem:hover .dropdownContent {
    display: flex;

}


.sublink {
    /* background-color: var(--light-background-color);
    padding: 10px; */
    margin: 4px 10px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    cursor: pointer;
    border-radius: 8px;
    text-decoration: none;
    color: var(--font-color);

}

.sublink:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.logout {
    display: flex;
    align-items: center;
    margin: 4px 10px;
    padding: 10px 10px;
    transition: background-color 0.3s;
    cursor: pointer;
    border-radius: 8px;
    color: var(--delete-btn);
}

.logout:hover {
    background-color: var(--light-red-background-color);

}


/* side meun style*/
/* hold sidebar */
.sidemenuContainer {
    margin-top: 50px;
    height: inherit;
    width: 240px;
    background-color: var(--light-background-color);
    overflow: auto;
    display: flex;
    flex-direction: column;

}

.sidemenuContainerColla {
    margin-top: 50px;
    height: inherit;
    width: 60px;
    background-color: var(--light-background-color);
    overflow: auto;
    font-weight: 600;
    display: flex;
    flex-direction: column;

}

.trailMenuCon {
    /* height: 50px; */
    padding: 12px 20px 0px 20px;

}

.trailMenuItem {
    background-color: var(--default-label);
    padding: 10px 30px 10px 30px;
    border-radius: 4px;
    text-align: center;
    display: block;
    text-decoration: none;
    color: var(--font-color);
}

.trailMenuConColla {
    margin: 10px auto;
    width: 32px;
    height: 32px;
    background-color: var(--default-label);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    text-decoration: none;
    color: var(--font-color);
}

.trailMenuItem:hover,
.trailMenuConColla:hover {
    background-color: #F9B700;

}


.sidemenu {
    overflow-y: auto;
    flex: 1;
    width: inherit;
    /* padding-top: 20px; */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.organizationInfo {
    height: 50px;
    box-shadow: var(--bottom-box-shadow);
    display: flex;
    padding-left: 20px;
    align-items: center;
    /* background-color: var(--white-color); */

}

.organizationLogo {
    width: 32px;
    height: 32px;
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.orgLogo {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: contain;
}

.organizationName {
    margin-left: 10px;
    font-size: var(--main-size);
    font-weight: 700;
}

/* firslt level meun with childrens style*/
.sideMenuItemChildren {
    margin: 20px 20px;
    display: block;
    transition: background-color 0.3s;
    cursor: pointer;
    color: var(--font-color);
    border-radius: 4px;
}


.sideMenuItemTitle .toggleBtn {
    transition: transform 0.3s;
    color: var(--arrow-color);
}

.sideMenuItemChildren:hover,
.sideMenuItemChildren.open,
.sideMenuItemChildren:hover .sideMenuItemTitle .toggleBtn {
    color: var(--main-color);
    box-shadow: var(--box-shadow);
    background-color: var(--menu-hover-color);
    color: var(--main-color);

}

.sideMenuItemChildren.open .sideMenuItemTitle .toggleBtn {
    transform: rotate(180deg);
    color: var(--main-color);
}

.sideMenuItemName {
    display: flex;
    align-items: center;
}
.sidemenuIcon {
    width: 22px;
    height: 22px;
}

.sideMenuItemChildren .sideMenuItemTitle:hover .sidemenuIcon g {
    stroke: var(--main-color);
}

.sideMenuItemChildren.open .sideMenuItemTitle .sidemenuIcon g {
    stroke: var(--main-color);
}

.sideMenuItemPlain:hover .sidemenuIcon g {
    stroke: var(--main-color);
}

.sideMenuItemPlain.pathSelected .sidemenuIcon g {

    stroke: var(--white-color);
}


.sideMenuItem {
    padding: 10px 20px;
    display: block;
    transition: background-color 0.3s;
    cursor: pointer;
    color: var(--font-color);
}

/* firslt level meun without childrens style*/
.sideMenuItemPlain {
    margin: 16px 20px 0px 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
    color: var(--font-color);
}


.sideMenuItemPlain:hover {
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    color: var(--main-color);
}


.sideMenuItemPlain.pathSelected {
    color: var(--white-color);
    background-color: var(--main-color);
}


.sideMenuItemNamePlain {
    margin-left: 10px;
}



.sideMenuItemTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    /* background-color: red; */
    padding: 10px 10px;
}

.sideMenuItemTitleName,
.menuLink {
    margin-left: 8px;
}

.sideMenuContent {
    display: none;
    overflow: hidden;
    white-space: nowrap;

}

.sideMenuItemChildren.open .sideMenuContent {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.subMenuItem {
    padding: 10px 10px 10px 20px;
    text-decoration: none;
    margin: 4px 10px;
    color: var(--font-color);
    border-radius: 4px;

}

.subMenuItem.pathSelected {
    background-color: var(--main-color);
    color: var(--white-color);
}

.subMenuItem:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.menuLink {
    padding: 10px 2px;
    /* display: inline-block; */
    /* background-color: red; */
    text-decoration: none;
    color: inherit;
}




/* collapsed menu css */
.orgLogoColla {
    padding-top: 20px;

}

.orgLogoCon {
    margin: 10px auto;
    width: 32px;
    height: 32px;
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.sideMenuItemColla {
    margin-top: 16px;
}

.collaIconPlain,
.collaIcon {
    width: 32px;
    height: 32px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.collaIconPlain {
    margin-top: 16px;
    text-decoration: none;
    cursor: pointer;
    color: var(--font-color);
}

.collaIconPlain:hover,
.collaIcon:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.collaIconPlain:hover .sidemenuIcon g,
.collaIcon:hover .sidemenuIcon g {
    stroke: var(--white-color);
}

.collaSubitemContainer {
    display: flex;
    flex-direction: column;
}


.subMenuContainer {
    display: none;
    position: absolute;
    left: 50px;
    margin-top: -32px;
    z-index: 3;
    background-color: var(--light-background-color);
    padding: 10px;
    box-shadow: var(--box-shadow-dark);
    border-radius: 4px;

}

.sideMenuItemColla:hover .subMenuContainer {
    display: block;
}

.collaSubitem {
    margin: 0 10px;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    color: var(--font-color);
    cursor: pointer;
    border-radius: 4px;
}

.collaSubitem:hover {
    background-color: var(--main-color);
    color: var(--white-color);

}

.collaSubitem.pathSelected {
    background-color: var(--slight-blue);
    color: var(--main-color);
}

.collaIconPlain:hover .menuNameTag {
    display: block;
}

.menuNameTag {
    display: none;
    position: absolute;
    left: 50px;
    z-index: 2;
    margin-top: 20px;
    background-color: var(--hover-title);
    padding: 2px 4px;
    border-radius: 4px;
    font-size: var(--label-size);
}

.helpWrapper {
    padding-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.helpCon {
    width: 50%;
    margin-bottom: 12px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: var(--main-color);
}

.helpCon:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    cursor: pointer;
}

.helpLogo {
    width: 20px;
    height: 20px;
}

.collapsedCon {
    width: inherit;
    /* height: 50%; */
    min-height: 60px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    background-color: var(--light-background-color);
    /* background-color: #fff; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.collapsedCon:hover {
    border-top: 1px solid var(--main-color);
    border-bottom: 1px solid var(--main-color);
    cursor: pointer;
    background-color: var(--main-color);
    color: var(--white-color);
}

.userGuideIcon {
    height: 100%;
    width: 120px;
    padding: 0 10px;
    text-decoration: none;
    color:var(--white-color);
    display:flex;
    justify-content:center;
    align-items:center;

}

.userGuideText {
    margin-left: 4px;
    color:inherit
}

.userGuideIcon:hover {
    color: var(--main-color);
    background: var(--light-background-color);
}
.trialCon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.scrollText {
    width: 360px;
    text-align: right;
    white-space: nowrap;
  overflow: hidden;
  display: block;
  padding: 0 16px;
  margin: auto;
  border-radius: 12px;
  background-size: 100%;
  color: white;
/* background-clip: text;
-webkit-text-fill-color: transparent; */
font-size: 16px;

}

.trialSubscribeBtn{
    display: flex;
    height: 32px;
    padding: 0 12px;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 20px;
    margin-left: 16px;
    background-image: linear-gradient(45deg, #444bf7, #7073d8);
}
/* @keyframes scrollText {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(50%);
    }
  } */
  