/* screen title */
.pageTitleContainer {
    height: 58px;
    background-color: var(--white-color);
    box-shadow: var(--bottom-box-shadow);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}


.pageTitle {
    /* margin: 10px 20px 10px 20px; */
    font-weight: 400;
}

.pageStatusNew {
    height: 30%;
    background-color: var(--edit-btn);
    padding: 4px;
    color: var(--white-color);
    border-radius: 2px;
    margin-right: 4px;
}

.pageStatusEdit {
    height: 30%;
    background-color: var(--default-label);
    padding: 4px;
    color: var(--white-color);
    border-radius: 2px;
    margin-right: 4px;
}

.actionBtn {
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.actionNameBtn {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}


.actionText {
   text-decoration: underline;
   color: var(--font-color);
   margin: 0px 10px;
}

.actionText:hover {
    cursor: pointer;
    color: var(--main-color);
}

.editBtn {
    margin: 0 10px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    color: var(--edit-btn);

}



.deleteBtn {
    margin: 0 10px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    color: var(--delete-btn);
}

.circleDeleteBtn {
    margin: 0 4px;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    color: var(--delete-btn);
}


.viewBtn {
    margin: 0 10px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    color: var(--main-color);
}



.cloneBtn {
    margin: 0 10px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    color: var(--default-label);
}



.downloadInvoiceBtn {
    margin: 0 10px;
    width: 19px;
    height: 19px;
    justify-content: center;
    align-items: center;
    background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/download_invoice_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.issueInvoiceBtn {
    margin: 0 10px;
    width: 19px;
    height: 19px;
    justify-content: center;
    align-items: center;
    background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/send_invoice_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.receiveStockBtn {
    margin: 0 10px;
    width: 19px;
    height: 19px;
    justify-content: center;
    align-items: center;
    color: var(--edit-btn);
}

.actionBtnTitle {
    display: none;
    position: absolute;
    left: 25px;
    font-size: var(--label-size);
    padding: 6px 8px;
    color: var(--white-color);
    background-color: var(--hover-title);
    border-radius: 4px;
    z-index: 10;
}
.actionBtnNameTitle {
    display: none;
    position: absolute;
    top:20px;
    left: 25px;
    font-size: var(--label-size);
    padding: 6px 8px;
    color: var(--white-color);
    background-color: var(--hover-title);
    border-radius: 4px;
    z-index: 10;
}
.actionName {
    font-size: var(--label-size);
}

.actionNameBtn:hover {
    text-decoration: underline;
}

.actionNameBtn:hover .actionBtnNameTitle,
.actionBtn:hover .actionBtnTitle {
    display: block;
}


/* /btns / */
.blueBtnCon {
    width: 100%;
    font-size: var(--main-size);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border: none;
    /* margin-left: 12px; */
    background-color: var(--main-color);
    color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    margin: 0px;
    transition: background-color 0.3s;
}

.blueBtnLinkCon {
    font-size: var(--main-size);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border: none;
    /* margin-left: 12px; */
    background-color: var(--main-color);
    color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    margin: 20px 0px;
    transition: background-color 0.3s;

}
.blueBtnLinkCon:hover,
.noMarginblueBtnCon:hover,
.blueBtnCon:hover {
    background-color: var(--main-hover);
    box-shadow: var(--box-shadow-dark);
}

.noMarginblueBtnCon {
    font-size: var(--main-size);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border: 1px sloid var(--main-color);
    background-color: var(--main-color);
    color: var(--white-color);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.outlineBtnCon {
    font-size: var(--main-size);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border: 1px solid var(--main-color);
    background-color: var(--white-color);
    color: var(--main-color);
    border-radius: 4px;
    cursor: pointer;
    margin: 20px 0px;
    transition: background-color 0.3s;
}

.outlineBtnCon:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    box-shadow: var(--box-shadow-dark);
}

.outlineBtnCon a {
    color: var(--main-color);
    text-decoration: none;
}

.outlineBtnCon:hover a {
    color: var(--white-color);
}

.xeroBtnCon {
    font-size: var(--main-size);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border: 1px solid var(--xero-blue);
    background-color: var(--white-color);
    color: var(--font-color);
    border-radius: 4px;
    cursor: pointer;
    margin: 20px 0px;
    transition: background-color 0.3s;
}
.xeroLogo {
    margin-right: 6px;
    width: 26px;
    height: 26px;
    background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/xero_logo_blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.xeroBtnCon:hover {
    color: var(--xero-blue);;
    box-shadow: var(--box-shadow-dark);
}

.disableblueBtnCon {
    font-size: var(--main-size);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    /* margin-left: 12px; */
    background-color: var(--gray-color);
    color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    margin: 20px 0px;
}

.loadingBtnCon {
    font-size: var(--main-size);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border: 1px solid var(--main-color);
    background-color: var(--white-color);
    color: var(--main-color);
    border-radius: 4px;
    margin: 20px 0px;

}


.greenBtnCon {

    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    background-color: var(--edit-btn);
    color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    transition: background-color 0.3s;
}


.greenBtnCon:hover {
    background-color: var(--edit-btn-hover);
    box-shadow: var(--box-shadow-dark);

}


.styledAddButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.styledDownloadButtonContainer {
    display: flex;
    justify-content: flex-start;
}


.addBtnArea {
    height: 44px;
    color: var(--white-color);
    background-color: var(--main-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    transition: background-color 0.3s;
}

.addBtnOutlineArea {
    font-size: var(--label-size);
    height: 40px;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    padding: 0 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.addBtnOutlineArea:hover{
    border: 1px solid var(--main-color);
    color: var(--white-color);
    background-color: var(--main-color);
}
.styledAddButton {
    margin-left: 4px;
}

.addBtnArea:hover,
.addBtnArea:hover .styledAddButton {
    background-color: var(--main-hover);
    box-shadow: var(--box-shadow-dark);
}

.lightBackBtnCon {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    margin: 20px 0px;
    transition: background-color 0.3s;
}



.lightBackBtnCon:hover {
    box-shadow: var(--box-shadow-dark);
}


.defaultlabel {
    background-color: var(--default-label);
    padding: 2px 4px;
    font-size: var(--label-size);
    color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
}


/* css for single checkbox */

/* Hide the browser's default checkbox */
.singleRatioCon {
    margin-top: 10px;
}
/* The container */

.checkboxContainer {
    display: block;
    position: relative;
    padding-left: 18px;
    cursor: pointer;
    font-size: var(--main-size);
    color: var(--font-color);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 18px;
    transition: background-color 0.3s;
}

.checkboxContainer.labelSize {
    font-size: var(--label-size);
}

.checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: var(--border-color);
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input~.checkmark {
    background-color: var(--gray-color);
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked~.checkmark {
    background-color: var(--main-color);

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkboxContainer:hover input~.checkmark:after,
.checkboxContainer input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-radius: 1px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* check box css end */


.gotoSettingBtn {
    text-decoration: none;
    color: var(--main-color);
    cursor: pointer;
}

 /* attrs table  style*/



.addBtn {
    display: flex;
    color: var(--edit-btn);
    margin: 8px;  
}

.addtext {
    cursor: pointer;
}
.addtext:hover {
    text-decoration: underline;
}


.inlineSaveBtnCon {
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: var(--label-size);
    background-color: var(--light-blue-color);
    color: var(--main-color);
    border-radius: 2px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    margin: 2px 2px;
    transition: background-color 0.3s;

}


.inlineSaveBtnCon:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.inlineCancelBtnCon {
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: var(--label-size);
    background-color: var(--light-red-background-color);
    color: var(--delete-btn);
    border-radius: 2px;
    cursor: pointer;
    margin: 2px 2px;
    transition: background-color 0.3s;
}

.inlineCancelBtnCon:hover {
    color: var(--white-color);   
    background-color: var(--delete-btn);
}

.downloadBtn {
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.downloadBtnCon {
    margin-right: 12px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    border-radius: 4px;
    border: 1px solid var(--download-btn);
    color: var(--download-btn);
}
.downloadBtnCon:hover {
    background-color: var(--download-btn);
    color: var(--white-color);
    cursor: pointer;
}

.emailBtnCon {
    margin-right: 12px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    border-radius: 4px;
    border: 1px solid var(--main-color);
    color: var(--main-color);
}
.emailBtnCon:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    cursor: pointer;
}
.barcodeScanCon {
    margin: 0 10px -4px 0;
    padding: 8px;
    cursor: pointer;
    color: var(--main-color);
    border: 1px solid var(--main-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.barcodeScanCon:hover {
    color: var(--white-color);
    background-color: var(--main-color);
}
.textLinkName {
    text-decoration: none;
    color: var(--main-color);
    font-size: var(--label-size);
}
.textLinkName:hover {
    text-decoration: underline;
}

.refreshButCon {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--main-color);
}
.refreshButCon:hover{
    text-decoration: underline;
}


