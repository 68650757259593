
/* input style  */
.styledPriceInput,
.styledPercentInput,
.styledInput,
.styledSelect,
.styledMultiSelect,
.styledTextarea {
    display: flex;
    flex-direction: column;
    margin: 12px 0px 0px 0px;
    color: var(--input-label);
    font-size: var(--label-size);
    flex: 1;
    position: relative;
}

.selectContainer {
    border: 1px solid var(--border-color);
    border-radius: 4px;

}

.selectContainer:hover,
.selectContainer:focus,
.selectContainerError:hover,
.selectContainerError:focus {
    border: 1px solid var(--main-color);
    background-image: none;
}

.selectContainerError {
    border: 1px solid var(--delete-btn);
    border-radius: 4px;
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/error_icon.svg");
    background-repeat: no-repeat;
    background-position: 88% center;
}

.selectContainer~.errorMessage,
.selectContainerError:hover~.errorMessage,
.selectContainerError:focus~.errorMessage {
    display: none;

}

.inputLabel {
    margin-bottom: 6px;
}
.inputLabelCon {
    display: flex;
    width: 100%;
}

.inputField {
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    line-height: 22px;
    color: var(--font-color);
    background-color: var(--white-color);
}

.inputField[readOnly] {
    background-color: var(--light-background-color);
}


.inputField[readOnly]:hover,
.inputField[readOnly]:focus{
    border-color: var(--border-color);
}

.styledInputReadonly
 {
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    line-height: 22px;
    color: var(--font-color);
    background-color: var(--light-background-color);
    min-height: 44px;
}


.inputFieldError {
    padding: 8px;
    border: 1px solid var(--delete-btn);
    border-radius: 4px;
    /*set the same height with select */
    line-height: 22px;
    color: var(--font-color);
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/error_icon.svg");
    background-repeat: no-repeat;
    background-position: 98% center;
}

.inputField:hover,
.inputField:focus,
.inputFieldError:hover,
.inputFieldError:focus {
    border: 1px solid var(--main-color);
    background-image: none;
    outline: none;
   

}

/* 同级兄弟选择器  */

.inputField~.errorMessage,
.inputFieldError:hover~.errorMessage,
.inputFieldError:focus~.errorMessage {
    display: none;
}

.errorMessage {
    display: block;
    margin-top: 2px;
    color: var(--delete-btn);
    font-size: var(--label-size);
}

.styledPriceInputContainer {
    padding-left: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    color: var(--font-color);
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.styledPriceInputContainerError {
    padding-left: 8px;
    border: 1px solid var(--delete-btn);
    border-radius: 4px;
    color: var(--font-color);
    display: flex;
    justify-content: center;
    align-items: center;

}

.passwordInputContainer {
    padding-left: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    color: var(--font-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.passwordInputContainerError {
    padding-left: 8px;
    border: 1px solid var(--delete-btn);
    border-radius: 4px;
    color: var(--font-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.passwordInputContainerError input,
.passwordInputContainer input {
    border: none;
    outline: none;
    width: 100%;
    padding: 8px 0px;
    line-height: 22px;
    color: var(--font-color);
    background-color: var(--white-color);
}

.passwordInputContainer:hover {
    border-color: var(--main-color);
}

.passwordIconCon {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 40px;
    height: 40px;
    background-color: var(--light-gray-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.passwordIcon {
    width: 16px;
    height: 16px;
}

.passwordInputContainerError:hover~.passwordHints,
.passwordInputContainer:hover~.passwordHints {
  display: block;
}
.passwordHints {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 60px;
  display: none;
  margin: 2px 0 12px 0;
  padding: 20px;
  background-color: var(--white-color);
  box-shadow: var(--box-shadow-card);
  z-index: 1;
}

.hintPassed {
  display: flex;
  align-items: center;
  color: var(--edit-btn);
  margin-top: 2px;
  font-size: var(--label-size);
}

.hintError {
  display: flex;
  align-items: center;
  color: var(--gray-color);
  margin-top: 2px;
  font-size: var(--label-size);
}

.percentInputContainer span,
.percentInputContainerError span,
.styledPriceInputContainer span,
.styledPriceInputContainerError span {
    color: var(--gray-color);
}


.styledPriceInputContainerError .inputField {
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/error_icon.svg");
    background-repeat: no-repeat;
    background-position: 98% center;
}



.percentInputContainer {
    /* background-color: red; */
    width: 30%;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px;
}


.percentInputContainerError .inputField {
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/error_icon.svg");
    background-repeat: no-repeat;
    background-position: 90% center;
}

.styledPriceInputContainerError .inputField:hover,
.percentInputContainerError .inputField:hover {
    background-image: none;
}


.percentInputContainerError {
    width: 30%;
    border: 1px solid var(--delete-btn);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px;
}


.styledPriceInputContainer:hover,
.styledPriceInputContainer:focus,
.styledPriceInputContainerError:hover,
.styledPriceInputContainerError:focus,
.percentInputContainer:hover,
.percentInputContainer:focus,
.percentInputContainerError:hover,
.percentInputContainerError:focus {
    border: 1px solid var(--main-color);
    background-image: none;

}

/* 带有% & $ 符号的input框样式 */
.styledPriceInputContainer .inputField,
.styledPriceInputContainer .inputField:focus,
.styledPriceInputContainer .inputField:hover,
.styledPriceInputContainerError .inputField,
.styledPriceInputContainerError .inputField:focus,
.styledPriceInputContainerError .inputField:hover,
.percentInputContainerError .inputField,
.percentInputContainerError .inputField:hover,
.percentInputContainerError .inputField:focus,
.percentInputContainer .inputField,
.percentInputContainer .inputField:hover,
.percentInputContainer .inputField:focus {
    width: 100%;
    border: none;
    outline: none;
}


.styledPriceInputContainer~.errorMessage,
.styledPriceInputContainerError:hover~.errorMessage,
.styledPriceInputContainerError:focus~.errorMessage,
.percentInputContainer~.errorMessage,
.percentInputContainerError:hover~.errorMessage,
.percentInputContainerError:focus~.errorMessage {
    display: none;

}

/* no border input */
.noBorderInput {
    width: calc(100% - 28px);
    border: 1px dashed var( --border-color);
    margin: 4px;
    line-height: 20px;
    padding: 8px;
    color: var(--font-color);
    background-color: var( --white-color);
    border-radius: 4px;
}

.noBorderInputError {
    padding: 8px;
    border: 1px solid var(--delete-btn);
    border-radius: 4px;
    /*set the same height with select */
    line-height: 20px;
    color: var(--font-color);
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/error_icon.svg");
    background-repeat: no-repeat;
    background-position: 98% center;

}

.noBorderInput:focus,
.noBorderInput:hover
 {
    outline: none;
    background-color: var(--white-color);
    border: 1px dashed var(--main-color)
}

/* .noBorderInput[readOnly] */
.noBorderInput[readOnly] {
    border: 1px dashed var( --border-color);
    background-color: var(--light-gray-color);
}


/* no border input */
.noBorderPercentInputCon {
  
    display: flex;
    align-items: center;
    font-size: var(--label-size);
    color: var(--gray-color);
}
.symbol {
    color: var(--gray-color);
}
.offTag {
    font-size: var(--tag-size);
}
.noBorderPercentInput {
    display: flex;
    width: 100%;
    border: 1px dashed var( --border-color);
    margin: 4px;
    line-height: 20px;
    padding: 8px;
    color: var(--font-color);
    background-color: var( --white-color);
    border-radius: 4px;
}

.noBorderPercentInput input {
    width: 100%;
    border: none;
}

.noBorderPercentInput input:focus,
.noBorderPercentInput input:hover {
    outline: none;
}


.teaxtareaField {
    padding: 8px;
    /* width: 462px; */
    border: 1px solid var(--border-color);
    border-radius: 3px;
    /*set the same height with select */
    line-height: 22px;
}



/* search */
.typingSearchCon,
.searchCon {
    display: flex;
    align-items: center;
}

.searchBoxCon {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    padding: 2px;
    border-radius: 4px;
    line-height: 22px;
    color: var(--font-color);
    background-color: var(--white-color);
   
}

.typingSearchBoxCon {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    padding: 2px;
    border-radius: 4px;
    line-height: 22px;
    color: var(--font-color);
    background-color: var(--white-color);
   
}
.typingSearchBoxCon:hover,
.searchBoxCon:hover {
    border-color: var(--main-color);  
}



.searchIcon {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    color: var(--gray-color);
}

.searchBox {
    width: 100%;
    border: none;
    border-left: 1px solid var(--light-background-color);
    padding: 8px;
    margin-left: 4px;
}

.typingSearchBox:focus,
.searchBox:focus {
    outline: none;
}

.searchBoxCon:hover,
.searchBoxCon:focus
 {
    width: 100%;
}



.searchBtnCon {
    background-color: var(--white-color);
    border: 1px solid var(--main-color);
    color: var(--main-color);
    border-radius: 4px;
    line-height: 24px;
    padding: 4px 20px;
    margin-left: 12px;
    cursor: pointer;
}



.searchBtnCon:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.typingSearchBox {
    width: 100%;
    border: none;
    padding: 8px;
    margin-left: 4px; 
}



/* data picker style  */
.datapickerCon {
    width: 100%;
    margin-top: 12px;
    font-size: var(--label-size);
    color: var(--input-label); 
}

.datapickerInputCon {
    width: 100%; 
    display: flex;
    border: 1px solid var(--border-color);
    margin-top: 6px;
    border-radius: 4px;
    background-color: var(--white-color);
    cursor: pointer;
    height: 40px;
}

.datapickerInputCon:focus,
.datapickerInputCon:hover {
    border-color: var(--main-color);
}

.datapickerInputCon.dateEror {
    border-color:var(--delete-btn);
}

.datapickerInputCon.dateEror:focus,
.datapickerInputCon.dateEror:hover {
    border-color:var(--main-color);
}

.datapickerInputCon.dateEror:hover~.errorMessage,
.datapickerInputCon.dateEror:focus~.errorMessage {
    display: none;

}

.datepicker {
    width: 100%;
    cursor: pointer;
}
