.container {
    display: flex;
    width: 100vw;
    height: 100vh;
    /* height: calc(100vh + 420px); */
    background-color: var(--light-background-color);
}


/*  login image */
.adverCon {
    background-color: var(--light-background-color);
    width: 51%;
    height: inherit;
    font-size: var(--label-size);
}
.adverTextCon {
    box-sizing: border-box;
    margin: 100px 0 20px 20%;
}
.adverText {
    font-size: 2rem;
    font-weight: 700; 
    color: var(--font-color);
    margin-bottom: 40px;
}

.bulletPointText {
    font-size: 1rem;
    margin: 12px 0 0 0px;
     color: var(--font-color);
}
.adverImageCon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.adverImage {
    width: 100%;
    margin: auto;
    object-fit: contain;
}

.loginContainer {
    height: inherit;
    flex: 1;
    background-color: var(--light-background-color);
    overflow: auto;
    box-sizing: border-box;
}

.loginFormCon {
    height: inherit;
    min-height: 720px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    overflow-y: auto;
}

.brandLogoCon {
    /* background-color: blue; */
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 200px;
    padding: 20px 40px 0px 0px;
    box-sizing: border-box;
}

.brandLogo {
    width: 120px;
    height: 40px;
    text-decoration: none;
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/stockunify-logo-black.svg");
    background-repeat: no-repeat;
    background-position: 80% top;
    background-size: 100%;
}

.linkCon {
    margin-top: 60px;
    width: 100%;
}

.link {
    align-self: flex-end;
    text-decoration: none;
    color: var(--main-color);

}

.link:hover {
    text-decoration: underline;
}
.signinlink {
    margin-top: 20px;
    padding: 10px;
    background-color: var(--main-color);
    color: var(--white-color);
    text-decoration: none;
    border-radius: 4px;


}
.signinlink:hover  {
    background-color: var(--main-hover);
}

.loginForm {
    width: 70%;
    padding: 20px 40px;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    background-color: var(--white-color);
}

.signin {
    margin: 20px 0px;

}

.tips {
    color: var(--gray-color);
    margin-bottom: 20px;
}

.tips:hover {
    color: var(--main-color);
    text-decoration: underline;
    cursor: pointer;
}

.signinText {
    color: var(--main-color);
}

.signinText:hover {
    text-decoration: underline;
    cursor: pointer;
}

.stepsContainer {
    display: flex;
    justify-content: space-between;
    margin: 30px 20px 30px 0px;
    align-items: center;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--gray-color);
}

.stepColored {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--main-color);
}

.stepColored:hover {
    text-decoration: underline;
    cursor: pointer;
}

.stepIcon {
    width: 16px;
    height: 16px;

}

.stepLine {
    background-color: var(--border-color);
    border: none;
    height: 1px;
    width: 20%;

}

.stepLineColored {
    background-color: var(--main-color);
    border: none;
    height: 1px;
    width: 20%;
}

.stepText {
    font-size: var(--label-size);
}

.passwordHints {
    display: block;
    margin: 2px 0 12px 0;

}

.hintPassed {
    display: flex;
    color: var(--edit-btn);
    margin-top: 2px;
    font-size: var(--label-size);
}

.hintError {
    display: flex;
    color: var(--gray-color);
    margin-top: 2px;
    font-size: var(--label-size);
}

.resendText {
    margin-top: 20px;
    color: var(--gray-color);
}

.termCon {
    display:flex;
    align-items: center;
}

.termLink {
    margin-top: 10px;
    margin-left: 2px;
    color: var(--main-color);
    text-decoration: none;
  
}
.termLink:hover {
    text-decoration: underline;
  
}

.termLinkText {
    margin-top: 10px;
    margin-left: 2px;
    color: var(--input-label);
    font-size: var(--main-size);
}

.orLineCon {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: center;
  }
  .lineContent {
    width: fit-content;
    background-color: var(--white-color);
    text-align: center;
    z-index: 2;
    padding: 0 40px;
    color: var(--gray-color);
    font-size: var(--main-size);
  }
  .line {
    position: absolute;
    top:50%;
    width: 100%;
    height: 1px;
    border-top: 1px solid var(--border-color);
  
  }

@media (max-width: 768px) {
    .adverCon {
        display: none;
    }
}


