.container {
    margin-top: 50px;
    width: 100vw;
    height: calc(100vh - 50px);
    background-color: var(--white-color);
    overflow: auto;
    box-sizing: border-box;
    color: var(--font-color);
}


.billingContentWrapper {
    display: flex;
    font-size: var(--main-size);
    padding:20px;
   
}

.billingSummaryWrapper {
  font-size: var(--main-size);
  max-width: 800px;
  margin: auto;
  padding: 0 40px;
}

.summaryCon {
    flex: 1;
    box-shadow: var(--box-shadow-card);
    margin-left: 20px;
    padding: 20px;
}

.summaryTitle {
  font-size: 1.2rem;
  font-weight: 500;
}
.textLabel {
  color: var(--font-color);
}
.billingSummaryTable {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}


.billingSummaryTable tr {
  height: 38px;
}

.billingSummaryTable td {
 
  text-align: left;
  vertical-align: middle;
  padding-left: 4px;
}

.billingSummaryTable tr.borderTr {
border-bottom: 1px solid var(--border-color);
}

.totalNum {
  font-size: 1.2rem;
  color: var(--main-color);
}
.extraUsersCon {

  display: flex;
  align-items: center;
 
}
.adjustIcon {
  width: 28px;
  height: 28px;
  color: var(--gray-color);
  
}
.adjustIcon:hover {
  cursor: pointer;
  color: var(--main-color);
}
.extraUserNum {
  margin: 0 12px;
}

.summaryPriceColumn {
  width: 40%;
}

.billingContentCon {
    width: 60%;
    box-shadow: var(--box-shadow-card);


}


.switchWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .switchCon{
    margin: 12px 12px;
    height: 28px;
    width: 60px;
    background-color: var(--main-color);
    border-radius: 25px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 5px;
    cursor: pointer;
    transition: all .3s;
  }
  
  .switchCon.annually {
    background-color: var(--edit-btn);
  }
  
  
  .handleSwitch {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: var(--white-color);
    overflow: hidden;
  }
  
  .annuallyCon {
    position: relative;
  }
  .discountTag {
    position: absolute;
    width: 100px;
    height: 30px;
    top: -10px;
    left: 100%;
    z-index: 2;
    background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/discount_logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    
    }
  
  
  .priceCardWrapper {
    display: flex;
    box-sizing: border-box;
    padding: 20px 10%;
  }
  
  .featuresTableWrapper {
    padding: 20px 10%;
  }
  
  .featuresTableWrapper table {
    width: 100%;
  }
  .featuresTableWrapper tr {
    height: 36px;
    text-align: center;
  }
  
  .featuresTableWrapper tr:nth-child(odd) {
    background-color: var(--white-color);
  }
  
  .featuresTableWrapper tr:nth-child(even) {
    background-color: var(--light-background-color);
  }
  
  .featuresTableWrapper th {
    vertical-align: middle;
    border-bottom: 1px solid var(--border-color);
  } 
  
  .featuresTableWrapper td {
    vertical-align: middle;
    padding-left: 4px;
  } 
  
  
  .featuresTableWrapper td.featureTableName {
    text-align: left;
  }
  
  
  
  .priceCard {
    width: 25%;
    border: 1px solid var(--border-color);
    margin-right: 20px;
    border-radius: 8px;
    position: relative;
    padding: 10px 0px;
    
  }
  .priceCard:hover {
    cursor: pointer;
    border-color: var(--main-color);
    background-color: var(--light-background-color)
  }
  .priceCardSelected {
    border-color: var(--main-color);
    background-color: var(--light-background-color)
  }
  .popularTag {
    position: absolute;
    background-color: var(--indicate-line);
    color: var(--white-color);
    font-size: var(--label-size);
    top: 0;
    left: 50%; /* Move .popularTag 50% to the left of .priceCard */
    transform: translate(-50%, 0);/* Move .popularTag back 50% of its own width */
    padding: 2px 4px;
  }

  
  
  .planTitle {
    margin-top: 30px;
    font-size: 1rem;
    text-align: center;
  }
  
  .planPriceCon {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .planPrice {
    font-size: 1.2rem;
  }
  .monthTag {
    margin-left: 6px;
    color: var(--gray-color);
  }
  
  .featuresCon {
    margin-top: 20px;
  }
  
  .featureItemCon {
    display: flex;
    align-items: center;
    margin: 20px;
  }
  
  .featureItemCon:hover {
    color: var(--main-color);
    cursor: pointer;
  }
  
  .featureIcon {
    color: var(--main-color);
  }
  
  .uncheckFeatureIcon {
    color: var(--gray-color);
  }
  
  .featureName {
    margin-left: 6px;
  }
  
  .userNumberCon {
    padding: 10px;
    border-radius: 20px;
    color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .userIcon {
    margin-right: 8px;
  }
  .userNum {
   margin-right: 2px;
  }

  /* billing steps */
.stepsCon {
    display: flex;
    padding: 40px 20%;
    margin: 0px 40px;
    align-items: center;
    justify-content: space-between;

}

.stepLine {
    background-color: var(--arrow-color);
    border: none;
    height: 1px;
    width: 40%;

}

.stepLineColored {
    background-color: var(--main-color);
    border: none;
    height: 1px;
    width: 20%;
}

.stepLineCompleted {
    background-color: var(--edit-btn);
    border: none;
    height: 1px;
    width: 20%;
}

.step {
    color: var(--arrow-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--label-size);
}

.stepName {
    margin-top: 12px;
    width: fit-content;
}

.step.active {
    color: var(--main-color);
}

.step.completed {
    color: var(--edit-btn);
}

.step.failed {
  color: var(--delete-btn);
}

.stepNum {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: var(--arrow-color);
    color: var(--white-color);
    border-radius: 22px;
    margin-right: 8px;
}

.stepNumActive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: var(--main-color);
    color: var(--white-color);
    border-radius: 22px;
    margin-right: 8px;
}

.stepNumCompleted {
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: var(--edit-btn);
    color: var(--white-color);
    border-radius: 22px;
    margin-right: 8px;
}

.stepNumFailed {
  display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: var(--light-red-background-color);
    color: var(--delete-btn);
    border-radius: 22px;
    margin-right: 8px;
}

.termCon {
  display:flex;
  align-items: center;
}
.termLink {
  margin-top: 10px;
  margin-left: 2px;
  color: var(--main-color);
  text-decoration: none;

}
.termLink:hover {
  text-decoration: underline;

}
.termLinkText {
  margin-top: 10px;
  margin-left: 2px;
  color: var(--input-label);
  font-size: var(--main-size);
}

.payStatusCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paySuccessIcon {
  width: 240px;
  height: 240px;
  background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/pay_success_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.payFailedIcon {
  width: 100px;
  height: 100px;
  background-image:url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/pay_failed_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.payStatusText {
  font-size: 1.2rem;
  margin: 10px 0;

}
.paySuccessSubText {
  font-size: var(--main-size);
}
.actionsCon {
  display: flex;

}

  